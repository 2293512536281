/**
 * Updates a new DX code array with the previous non-over-writeable fields from the old DX array.
 * When dx codes update, they don't have the attached status and plan data.
 * we need that data to carry over so that it is not lost
 *
 * (Allows status and plan Progress Note fields
 * to not be overwritten on old codes when adding new codes)
 *
 * @param newDxData - The new array of Dx Codes (we added or removed one)
 * @param oldOptionsData - The old array of Dx Codes
 * @returns {array} The new array, with the fields that shouldn't be overwritten of the old array
 */
function maintainDxOptionData(newDxData, oldOptionsData) {
  let updatedArray = []

  // keep status and plan for unchanging dx codes
  newDxData?.forEach((dxItem) => {
    let existingOldItem = oldOptionsData.find(
      (oldItem) => oldItem.code && dxItem.code && oldItem.code === dxItem.code,
    )
    if (existingOldItem) {
      updatedArray.push({ ...existingOldItem, ...dxItem })
    } else {
      updatedArray.push(dxItem)
    }
  })

  return updatedArray
}

function sortDiagnosisDescription(dxA, dxB) {
  return dxA.description.localeCompare(dxB.description)
}

function sortDiagnosisCount(dxA, dxB) {
  let aCount = dxA?.count || -1
  let bCount = dxB?.count || -1
  return bCount - aCount || sortDiagnosisCode(dxA, dxB)
}

// choosing not to touch this pre-existing convoluted logic
// for sorting DxCodes
function sortDiagnosisCode(dxA, dxB) {
  if (dxA === dxB) {
    return 0
  }
  const aArr = dxA.code.split('.')
  const bArr = dxB.code.split('.')

  for (let i = 0; i < Math.min(aArr.length, bArr.length); i++) {
    if (aArr[i] < bArr[i]) {
      return -1
    }
    if (aArr[i] > bArr[i]) {
      return 1
    }
  }
  if (aArr.length < bArr.length) {
    return -1
  }
  if (aArr.length > bArr.length) {
    return 1
  }
  return 0
}

export { maintainDxOptionData, sortDiagnosisDescription, sortDiagnosisCode, sortDiagnosisCount }
