import moment from 'moment'
import { currentMonth, currentYear, today } from 'src/views/pages/PracticeIQ/StaticData'
export const getDates = (startDate, stopdDate) => {
  let dateArray = []
  let currentDate = moment(startDate)
  let stopDate = moment(stopdDate)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}
export const filterByCalendar = async (data, id) => {
  const processedData = {
    processedMonthData: [],
    currentMonthData: [],
    todaysData: [{ month: today, encounters: 0, id: 'today' }],
    weekData: [],
    daysInMonthData: [],
    prevMonthProceseedData: [],
    prevweekData: [],
    dayOrMonthData: [],
    dayOrMonthData1: [],
  }

  const initialProcessData = {
    monthWiseData: {},
    weekWiseData: {},
    daysMonthData: {},
    prevMonth: {},
    prevweekWiseData: {},
    dayOrMonthData: {},
    dayOrMonthData1: {},
  }
  const filtResults = []
  const filtResults1 = []
  const filtResults2 = []
  // let count = 0
  await data?.map((x) => {
    // count += x.total_count
    initialProcessData.dayOrMonthData[moment(x._id.service_date_from).format('MMMM-YYYY')] =
      typeof initialProcessData.dayOrMonthData[
        moment(x._id.service_date_from).format('MMMM-YYYY')
      ] !== 'undefined'
        ? initialProcessData.dayOrMonthData[moment(x._id.service_date_from).format('MMMM-YYYY')] +
          x.total_count
        : x.total_count
    filtResults1.push(x)
    initialProcessData.dayOrMonthData1[moment(x._id.service_date_from).format('MM-DD-YYYY')] =
      typeof initialProcessData.dayOrMonthData1[
        moment(x._id.service_date_from).format('MM-DD-YYYY')
      ] !== 'undefined'
        ? initialProcessData.dayOrMonthData1[moment(x._id.service_date_from).format('MM-DD-YYYY')] +
          x.total_count
        : x.total_count
    filtResults2.push(x)

    if (moment(x._id.service_date_from).isSame(new Date(), 'month') && id === currentMonth) {
      initialProcessData.daysMonthData[moment(x._id.service_date_from).format('MM-DD-YYYY')] =
        typeof initialProcessData.daysMonthData[
          moment(x._id.service_date_from).format('MM-DD-YYYY')
        ] !== 'undefined'
          ? initialProcessData.daysMonthData[moment(x._id.service_date_from).format('MM-DD-YYYY')] +
            x.total_count
          : x.total_count
      filtResults.push(x)
    }

    if (id === currentYear) {
      initialProcessData.monthWiseData[moment(x._id.service_date_from).format('YYYY MMMM')] =
        typeof initialProcessData.monthWiseData[
          moment(x._id.service_date_from).format('YYYY MMMM')
        ] !== 'undefined'
          ? initialProcessData.monthWiseData[moment(x._id.service_date_from).format('YYYY MMMM')] +
            x.total_count
          : x.total_count
      filtResults.push(x)
    }

    if (moment(x._id.service_date_from).isSame(new Date(), 'week') && id === 'This week') {
      initialProcessData.weekWiseData[moment(x._id.service_date_from).format('MM-DD-YYYY')] =
        typeof initialProcessData.weekWiseData[
          moment(x._id.service_date_from).format('MM-DD-YYYY')
        ] !== 'undefined'
          ? initialProcessData.weekWiseData[moment(x._id.service_date_from).format('MM-DD-YYYY')] +
            x.total_count
          : x.total_count
      filtResults.push(x)
    }
    if (
      moment(new Date()).subtract(1, 'months').isSame(x._id.service_date_from, 'month') &&
      id === 'Previous month'
    ) {
      initialProcessData.prevMonth[moment(x._id.service_date_from).format('MM-DD-YYYY')] =
        typeof initialProcessData.prevMonth[
          moment(x._id.service_date_from).format('MM-DD-YYYY')
        ] !== 'undefined'
          ? initialProcessData.prevMonth[moment(x._id.service_date_from).format('MM-DD-YYYY')] +
            x.total_count
          : x.total_count
      filtResults.push(x)
    }

    if (
      moment(new Date()).subtract(1, 'weeks').isSame(x._id.service_date_from, 'week') &&
      id === 'Previous week'
    ) {
      initialProcessData.prevweekWiseData[moment(x._id.service_date_from).format('MM-DD-YYYY')] =
        typeof initialProcessData.prevweekWiseData[
          moment(x._id.service_date_from).format('MM-DD-YYYY')
        ] !== 'undefined'
          ? initialProcessData.prevweekWiseData[
              moment(x._id.service_date_from).format('MM-DD-YYYY')
            ] + x.total_count
          : x.total_count
      filtResults.push(x)
    }
  })

  if (Object.keys(initialProcessData.dayOrMonthData).length === 0) {
    processedData.dayOrMonthData.push({
      month: moment().format('MM-DD-YYYY'),
      encounters: 0,
      id: 'year',
    })
  } else {
    for (let prop in initialProcessData.dayOrMonthData) {
      processedData.dayOrMonthData.push({
        monthinitial: moment(prop).format('DD-MM-YYYY'),
        month: prop,
        encounters: initialProcessData.dayOrMonthData[prop],
        id: 'year',
      })
    }
  }
  if (Object.keys(initialProcessData.dayOrMonthData1).length === 0) {
    processedData.dayOrMonthData1.push({
      month: moment().format('MM-DD-YYYY'),
      encounters: 0,
      id: 'days',
    })
  } else {
    for (let prop in initialProcessData.dayOrMonthData1) {
      processedData.dayOrMonthData1.push({
        monthinitial: moment(prop).format('MM-DD-YYYY'),
        month: prop,
        encounters: initialProcessData.dayOrMonthData1[prop],
        id: 'days',
      })
    }
  }
  if (Object.keys(initialProcessData.monthWiseData).length === 0) {
    processedData.processedMonthData.push({
      month: moment().format('MM-DD-YYYY'),
      encounters: 0,
      id: 'year',
    })
  } else {
    for (let prop in initialProcessData.monthWiseData) {
      if (prop === currentMonth) {
        processedData.currentMonthData.push({
          month: prop,
          encounters: initialProcessData.monthWiseData[prop],
          id: 'currentMonth',
        })
      }
      processedData.processedMonthData.push({
        monthinitial: moment(prop).format('DD-MM-YYYY'),
        month: prop,
        encounters: initialProcessData.monthWiseData[prop],
        id: 'year',
      })
    }
  }

  if (Object.keys(initialProcessData.weekWiseData).length === 0) {
    processedData.weekData.push({ month: moment().format('MM-DD-YYYY'), encounters: 0, id: 'week' })
  } else {
    for (let prop in initialProcessData.weekWiseData) {
      processedData.weekData.push({
        month: prop,
        encounters: initialProcessData.weekWiseData[prop],
        id: 'week',
      })
    }
  }
  if (Object.keys(initialProcessData.prevweekWiseData).length === 0) {
    processedData.prevweekData.push({
      month: moment().format('MM-DD-YYYY'),
      encounters: 0,
      id: 'lastweek',
    })
  } else {
    for (let prop in initialProcessData.prevweekWiseData) {
      processedData.prevweekData.push({
        month: prop,
        encounters: initialProcessData.prevweekWiseData[prop],
        id: 'lastweek',
      })
    }
  }
  if (Object.keys(initialProcessData.daysMonthData).length === 0) {
    processedData.daysInMonthData.push({
      month: moment().format('MM-DD-YYYY'),
      encounters: 0,
      id: 'currentMonth',
    })
  } else {
    for (let prop in initialProcessData.daysMonthData) {
      processedData.daysInMonthData.push({
        month: prop,
        encounters: initialProcessData.daysMonthData[prop],
        id: 'currentMonth',
      })
    }
  }
  if (Object.keys(initialProcessData.prevMonth).length === 0) {
    processedData.prevMonthProceseedData.push({
      month: moment().format('MM-DD-YYYY'),
      encounters: 0,
      id: 'lastMonth',
    })
  } else {
    for (let prop in initialProcessData.prevMonth) {
      processedData.prevMonthProceseedData.push({
        month: prop,
        encounters: initialProcessData.prevMonth[prop],
        id: 'lastMonth',
      })
    }
  }

  if (id === currentYear) {
    return { data: processedData.processedMonthData, filtResults }
  } else if (id === currentMonth) {
    return { data: processedData.daysInMonthData, filtResults }
  } else if (id === 'Previous month') {
    return { data: processedData.prevMonthProceseedData, filtResults }
  } else if (id === 'This week') {
    return { data: processedData.weekData, filtResults }
  } else if (id === today) {
    return processedData.todaysData
  } else if (id === 'Previous week') {
    return { data: processedData.prevweekData, filtResults }
  } else {
    return {
      data:
        processedData.dayOrMonthData1.length > 31
          ? processedData.dayOrMonthData
          : processedData.dayOrMonthData1,
      filtResults: processedData.dayOrMonthData1.length > 31 ? filtResults1 : filtResults2,
    }
  }
}

let practiceProvider = []

let mappedProvidersObject = {}
let mappedFacilityObject = {}

export const GetNames = (x) => {
  practiceProvider = x
  const mappedproviders = JSON.parse(localStorage.getItem('currentPractice'))?.mappedProviders

  if (mappedproviders) {
    for (let provider of mappedproviders) {
      mappedProvidersObject[provider._id] = provider.organization_name
        ? provider.organization_name
        : provider.last_name + ', ' + provider.first_name
    }
  }
}

export const GetFaciltyNames = (x) => {
  practiceProvider = x
  const mappedproviders = JSON.parse(localStorage.getItem('currentPractice'))?.mappedFacilities

  if (mappedproviders) {
    for (let provider of mappedproviders) {
      mappedFacilityObject[provider._id] = provider.name
        ? provider.name
        : provider.name + ', ' + provider.name
    }
  }
}

export const getProvidername = (_id) => {
  return mappedProvidersObject[_id] ? mappedProvidersObject[_id] : 'Others'
}
export const getSort = (_id) => {
  return _id.sort((a, b) => moment(a.month) - moment(b.month))
}
//

export const getGroupedData = async (id, data, facilityList, paramx = '') => {
  let code_gp = []
  let initialObj = {}
  let graphData = []
  await GetFaciltyNames()
  const get_name = (x) => {
    return mappedFacilityObject[x] ? mappedFacilityObject[x] : 'Others'
  }
  if (id === 'Code group') {
    await data.map((x, indx) => {
      const graphObj = { label: '', data: [] }
      if (indx === 0) {
        initialObj[x.proc_group_name] = x.total_count
        graphObj.label = x.proc_group_name
        graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
        graphData.push(graphObj)
      } else {
        initialObj[x.proc_group_name] =
          typeof initialObj[x.proc_group_name] !== 'undefined'
            ? initialObj[x.proc_group_name] + x.total_count
            : x.total_count
        const foundIndx = graphData.findIndex((y) => y.label === x.proc_group_name)
        if (foundIndx === -1) {
          graphObj.label = x.proc_group_name
          graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
          graphData.push(graphObj)
        } else {
          const dateIndx = graphData[foundIndx].data.findIndex(
            (y) => y.month === x._id.service_date_from,
          )
          if (dateIndx === -1) {
            graphData[foundIndx].data.push({
              month: x._id.service_date_from,
              encounters: x.total_count,
            })
          } else {
            graphData[foundIndx].data[dateIndx].encounters =
              x.total_count + graphData[foundIndx].data[dateIndx].encounters
          }
        }
      }
    })
    if (Object.keys(initialObj).length === 0) {
      code_gp.push({ month: 'Others', encounters: 0, name: 'Code group' })
    } else {
      for (const key in initialObj) {
        code_gp.push({ month: key, encounters: initialObj[key] })
      }
    }
  }
  if (id === 'Payment type') {
    await data.map((x, indx) => {
      const graphObj = { label: '', data: [] }
      if (indx === 0) {
        initialObj[x._id.payment_type] = x.total_count
        graphObj.label = x._id.payment_type
        graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
        graphData.push(graphObj)
      } else {
        initialObj[x._id.payment_type] =
          typeof initialObj[x._id.payment_type] !== 'undefined'
            ? initialObj[x._id.payment_type] + x.total_count
            : x.total_count
        const foundIndx = graphData.findIndex((y) => y.label === x._id.payment_type)
        if (foundIndx === -1) {
          graphObj.label = x._id.payment_type
          graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
          graphData.push(graphObj)
        } else {
          const dateIndx = graphData[foundIndx].data.findIndex(
            (y) => y.month === x._id.service_date_from,
          )
          if (dateIndx === -1) {
            graphData[foundIndx].data.push({
              month: x._id.service_date_from,
              encounters: x.total_count,
            })
          } else {
            graphData[foundIndx].data[dateIndx].encounters =
              x.total_count + graphData[foundIndx].data[dateIndx].encounters
          }
        }
      }
    })
    if (Object.keys(initialObj).length === 0) {
      code_gp.push({ month: 'Others', encounters: 0, name: 'Payment type' })
    } else {
      for (const key in initialObj) {
        code_gp.push({ month: key, encounters: initialObj[key] })
      }
    }
  }
  if (id === 'Code') {
    await data.map((x, indx) => {
      const graphObj = { label: '', data: [] }
      if (indx === 0) {
        initialObj[
          `${x._id.proc_code}  (${
            typeof x.proc_code_name !== 'undefined' ? x.proc_code_name : 'Others'
          })`
        ] = x.total_count
        graphObj.label = x._id.proc_code
        graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
        graphData.push(graphObj)
      } else {
        initialObj[
          `${x._id.proc_code}  (${
            typeof x.proc_code_name !== 'undefined' ? x.proc_code_name : 'Others'
          })`
        ] =
          typeof initialObj[
            `${x._id.proc_code}  (${
              typeof x.proc_code_name !== 'undefined' ? x.proc_code_name : 'Others'
            })`
          ] !== 'undefined'
            ? initialObj[
                `${x._id.proc_code}  (${
                  typeof x.proc_code_name !== 'undefined' ? x.proc_code_name : 'Others'
                })`
              ] + x.total_count
            : x.total_count

        const foundIndx = graphData.findIndex((y) => y.label === x._id.proc_code)
        if (foundIndx === -1) {
          graphObj.label = x._id.proc_code
          graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
          graphData.push(graphObj)
        } else {
          const dateIndx = graphData[foundIndx].data.findIndex(
            (y) => y.month === x._id.service_date_from,
          )
          if (dateIndx === -1) {
            graphData[foundIndx].data.push({
              month: x._id.service_date_from,
              encounters: x.total_count,
            })
          } else {
            graphData[foundIndx].data[dateIndx].encounters =
              x.total_count + graphData[foundIndx].data[dateIndx].encounters
          }
        }
      }
    })
    if (Object.keys(initialObj).length === 0) {
      code_gp.push({ month: 'Others', encounters: 0, name: 'Code' })
    } else {
      for (const key in initialObj) {
        code_gp.push({
          month: key,
          encounters: initialObj[key],
        })
      }
    }
  }

  if (id === 'Facility' && facilityList.length > 0) {
    await data.map((x, indx) => {
      const graphObj = { label: '', data: [] }
      if (indx === 0) {
        initialObj[x._id.facility_id] = x.total_count
        graphObj.label = x._id.facility_id
        graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
        graphData.push(graphObj)
      } else {
        initialObj[x._id.facility_id] =
          typeof initialObj[x._id.facility_id] !== 'undefined'
            ? initialObj[x._id.facility_id] + x.total_count
            : x.total_count

        //
        const foundIndx = graphData.findIndex((y) => y.label === x._id.facility_id)
        if (foundIndx === -1) {
          graphObj.label = x._id.facility_id
          graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
          graphData.push(graphObj)
        } else {
          const dateIndx = graphData[foundIndx].data.findIndex(
            (y) => y.month === x._id.service_date_from,
          )
          if (dateIndx === -1) {
            graphData[foundIndx].data.push({
              month: x._id.service_date_from,
              encounters: x.total_count,
            })
          } else {
            graphData[foundIndx].data[dateIndx].encounters =
              x.total_count + graphData[foundIndx].data[dateIndx].encounters
          }
        }
      }
    })
    graphData = graphData.map((x) => {
      return {
        ...x,
        data: getSort(x.data),
        label: get_name(x.label),
      }
    })

    if (Object.keys(initialObj).length === 0) {
      code_gp.push({ month: 'Others', encounters: 0, name: 'Facility' })
    } else {
      for (const key in initialObj) {
        code_gp.push({
          month: get_name(key),
          encounters: initialObj[key],
          name: key,
        })
      }
    }
  }

  if (id === 'Place of service' && facilityList.length > 0) {
    await data.map((x, indx) => {
      const graphObj = { label: 'Others', data: [] }
      if (indx === 0) {
        initialObj[x._id.facility_id] = x.total_count
        graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
        graphData.push(graphObj)
      } else {
        initialObj[x._id.facility_id] =
          typeof initialObj[x._id.facility_id] !== 'undefined'
            ? initialObj[x._id.facility_id] + x.total_count
            : x.total_count
        //

        const dateIndx = graphData[0].data.findIndex((y) => y.month === x._id.service_date_from)
        if (dateIndx === -1) {
          graphData[0].data.push({
            month: x._id.service_date_from,
            encounters: x.total_count,
          })
        } else {
          graphData[0].data[dateIndx].encounters =
            x.total_count + graphData[0].data[dateIndx].encounters
        }
      }
    })
    let count = 0
    for (const key in initialObj) {
      count += initialObj[key]
    }
    code_gp.push({ month: 'Others', encounters: count })
  }

  if (id === 'Payor') {
    await data.map((x, indx) => {
      const graphObj = { label: '', data: [] }
      if (indx === 0) {
        initialObj[x._id.payor] = x.total_count
        graphObj.label = x._id.payor
        graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
        graphData.push(graphObj)
      } else {
        initialObj[x._id.payor] =
          typeof initialObj[x._id.payor] !== 'undefined'
            ? initialObj[x._id.payor] + x.total_count
            : x.total_count
        const foundIndx = graphData.findIndex((y) => y.label === x._id.payor)
        if (foundIndx === -1) {
          graphObj.label = x._id.payor
          graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
          graphData.push(graphObj)
        } else {
          const dateIndx = graphData[foundIndx].data.findIndex(
            (y) => y.month === x._id.service_date_from,
          )
          if (dateIndx === -1) {
            graphData[foundIndx].data.push({
              month: x._id.service_date_from,
              encounters: x.total_count,
            })
          } else {
            graphData[foundIndx].data[dateIndx].encounters =
              x.total_count + graphData[foundIndx].data[dateIndx].encounters
          }
        }
      }
    })
    if (Object.keys(initialObj).length === 0) {
      code_gp.push({ month: 'Others', encounters: 0, name: ' Payor' })
    } else {
      for (const key in initialObj) {
        code_gp.push({ month: key, encounters: initialObj[key] })
      }
    }
  }
  if (id === 'Provider') {
    GetNames()
    await data.map((x, indx) => {
      const graphObj = { label: '', data: [] }
      if (indx === 0) {
        initialObj[x._id.provider_id] = x.total_count
        graphObj.label = x._id.provider_id
        graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
        graphData.push(graphObj)
      } else {
        initialObj[x._id.provider_id] =
          typeof initialObj[x._id.provider_id] !== 'undefined'
            ? initialObj[x._id.provider_id] + x.total_count
            : x.total_count

        //
        const foundIndx = graphData.findIndex((y) => y.label === x._id.provider_id)
        if (foundIndx === -1) {
          graphObj.label = x._id.provider_id
          graphObj.data.push({ month: x._id.service_date_from, encounters: x.total_count })
          graphData.push(graphObj)
        } else {
          const dateIndx = graphData[foundIndx].data.findIndex(
            (y) => y.month === x._id.service_date_from,
          )
          if (dateIndx === -1) {
            graphData[foundIndx].data.push({
              month: x._id.service_date_from,
              encounters: x.total_count,
            })
          } else {
            graphData[foundIndx].data[dateIndx].encounters =
              x.total_count + graphData[foundIndx].data[dateIndx].encounters
          }
        }
      }
    })

    graphData = graphData.map((x) => {
      return { ...x, label: getProvidername(x.label) }
    })

    if (Object.keys(initialObj).length === 0) {
      code_gp.push({ month: 'Others', encounters: 0, name: 'provider' })
    } else {
      for (const key in initialObj) {
        code_gp.push({
          month: getProvidername(key),
          encounters: initialObj[key],
          name: key,
        })
      }
    }
  }
  if (id === 'Date') {
    if (paramx !== '') {
      GetNames()
      await data.map((x, indx) => {
        const graphObj = { label: '', data: [] }
        if (indx === 0) {
          initialObj[x._id.service_date_from] = x.total_count
          graphObj.label = x._id.service_date_from
          graphObj.data.push({
            month: moment(x._id.service_date_from).format('MM-DD-YYYY'),
            encounters: x.total_count,
          })
          graphData.push(graphObj)
        } else {
          initialObj[x._id.service_date_from] =
            typeof initialObj[x._id.service_date_from] !== 'undefined'
              ? initialObj[x._id.service_date_from] + x.total_count
              : x.total_count

          //
          const foundIndx = graphData.findIndex((y) => y.label === x._id.service_date_from)
          if (foundIndx === -1) {
            graphObj.label = x._id.service_date_from
            graphObj.data.push({
              month: moment(x._id.service_date_from).format('MM-DD-YYYY'),
              encounters: x.total_count,
            })
            graphData.push(graphObj)
          } else {
            const dateIndx = graphData[foundIndx].data.findIndex(
              (y) => y.month === x._id.service_date_from,
            )
            if (dateIndx === -1) {
              graphData[foundIndx].data.push({
                month: moment(x._id.service_date_from).format('MM-DD-YYYY'),
                encounters: x.total_count,
              })
            } else {
              graphData[foundIndx].data[dateIndx].encounters =
                x.total_count + graphData[foundIndx].data[dateIndx].encounters
            }
          }
        }
      })

      graphData = graphData.map((x) => {
        return { ...x, label: getProvidername(x.label) }
      })

      if (Object.keys(initialObj).length === 0) {
        code_gp.push({ month: 'Others', encounters: 0, name: 'date' })
      } else {
        for (const key in initialObj) {
          code_gp.push({
            month: moment(key).format('MM-DD-YYYY'),
            encounters: initialObj[key],
            // name: key,
          })
        }
      }
    } else {
      GetNames()
      await data.map((x, indx) => {
        const graphObj = { label: '', data: [] }
        if (indx === 0) {
          initialObj[moment(x._id.service_date_from).format('MMMM-YYYY')] = x.total_count
          graphObj.label = moment(x._id.service_date_from).format('MMMM-YYYY')
          graphObj.data.push({
            month: moment(x._id.service_date_from).format('MMMM-YYYY'),
            encounters: x.total_count,
          })
          graphData.push(graphObj)
        } else {
          initialObj[moment(x._id.service_date_from).format('MMMM-YYYY')] =
            typeof initialObj[moment(x._id.service_date_from).format('MMMM-YYYY')] !== 'undefined'
              ? initialObj[moment(x._id.service_date_from).format('MMMM-YYYY')] + x.total_count
              : x.total_count

          //
          const foundIndx = graphData.findIndex(
            (y) => y.label === moment(x._id.service_date_from).format('MMMM-YYYY'),
          )
          if (foundIndx === -1) {
            graphObj.label = moment(x._id.service_date_from).format('MMMM-YYYY')
            graphObj.data.push({
              month: moment(x._id.service_date_from).format('MMMM-YYYY'),
              encounters: x.total_count,
            })
            graphData.push(graphObj)
          } else {
            const dateIndx = graphData[foundIndx].data.findIndex(
              (y) => y.month === moment(x._id.service_date_from).format('MMMM-YYYY'),
            )
            if (dateIndx === -1) {
              graphData[foundIndx].data.push({
                month: moment(x._id.service_date_from).format('MMMM-YYYY'),
                encounters: x.total_count,
              })
            } else {
              graphData[foundIndx].data[dateIndx].encounters =
                x.total_count + graphData[foundIndx].data[dateIndx].encounters
            }
          }
        }
      })

      graphData = graphData.map((x) => {
        return { ...x, label: getProvidername(x.label) }
      })

      if (Object.keys(initialObj).length === 0) {
        code_gp.push({ month: 'Others', encounters: 0, name: 'date' })
      } else {
        for (const key in initialObj) {
          code_gp.push({
            monthinitial: moment(key).format('DD-MM-YYYY'),
            month: moment(key).format('MMMM-YYYY'),
            encounters: initialObj[key],
            // name: key,
          })
        }
      }
    }
  }

  return { code_gp, graphData }
}
