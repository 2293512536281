import { configureStore } from '@reduxjs/toolkit'
import loginReducer from 'src/features/login/loginSlice'
import layoutReducer from 'src/features/layout/layoutSlice'
import encountersReducer from 'src/features/encounters/encountersSlice'
import censusReducer from 'src/features/dailyCensus/censusSlice'
import patientDetailsReducer from 'src/features/patient/patientDetailsSlice'
import demographicsReducer from 'src/features/demographics/demographicsSlice'
import forgotPasswordSlice from 'src/features/forgotPassword/forgotPasswordSlice'
import progressNoteSlice from 'src/features/progressNote/progressNoteSlice'
import providerHoldReducer from 'src/features/providerHold/providerHoldSlice'
import practiceIqReducer from 'src/features/practiceIq/PracticeIqSlice'
import dashboardReducer from 'src/features/dashbord/dashbordSlice'

import MessageReducer from 'src/features/messages/messageSlice'

export const allReducers = {
  user: loginReducer,
  layout: layoutReducer,
  encounters: encountersReducer,
  census: censusReducer,
  patientDetails: patientDetailsReducer,
  forgotPassword: forgotPasswordSlice,
  progressNote: progressNoteSlice,
  providerHold: providerHoldReducer,
  practiceIq: practiceIqReducer,
  dashboard: dashboardReducer,
  messages: MessageReducer,
  demographics: demographicsReducer,
}

const store = configureStore({
  reducer: allReducers,
})

export default store
