import axios from 'axios'
import { setupInterceptorsTo } from './helper.ts'

const fusionApi = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_FUSION_BASEURL,
    headers: {
      'Content-Type': 'application/json',
    },
  }),
)

export async function getFusion(url, config = {}) {
  return await fusionApi
    .get(url, {
      ...config,
    })
    .then((response) => response.data)
    .catch((e) => {
      console.error(e)
      return null
    })
}

export async function postFusion(url, data, config = {}) {
  return fusionApi
    .post(url, { ...data }, { ...config })
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      return e
    })
}
