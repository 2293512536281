import axios from 'axios'
import { setupInterceptorsTo } from './helper.ts'

const collectorApi = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_COLLECTOR_BASEURL,
    headers: {
      'Content-Type': 'application/json',
      // Origin: "claimocity",
    },
  }),
)

const collectorSkipInterceptorApi = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_COLLECTOR_BASEURL,
  }),
)
export async function getCollector(url, config = {}) {
  return await collectorApi
    .get(url, {
      ...config,
    })
    .then((response) => response.data)
    .catch((e) => {
      console.error(e)
      return e
    })
}

export async function postCollector(url, data, config = {}) {
  return collectorApi
    .post(url, { ...data }, { ...config })
    .then((response) => {
      return response.data
    })
    .catch((e) => {
      return e
    })
}

export async function bulkPostCollector(url, data, config = {}) {
  return collectorApi
    .post(url, data, { ...config })
    .then((response) => response.data)
    .catch((e) => {
      return e
    })
}

export async function putCollector(url, data, config = {}) {
  return collectorApi.put(url, { ...data }, { ...config }).then((response) => response.data)
}

export async function putCollectorArr(url, data, config = {}) {
  return collectorApi.put(url, data, { ...config }).then((response) => response.data)
}

export async function bulkPutCollector(url, data, config = {}) {
  return collectorApi.put(url, data, { ...config }).then((response) => response.data)
}
export async function delCollector(url, config = {}) {
  return await collectorApi.delete(url, { ...config }).then((response) => response.data)
}
