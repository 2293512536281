import { getMips } from './backendHelper'

// transforms a MM/DD/YYYY date to a YYYY-MM-DD date
export function convertMipsSlashDateToHyphenDate(slashDate) {
  if (!slashDate) {
    return null
  }

  const parts = slashDate.split('/')

  return parts[2] + '-' + parts[0].padStart(2, '0') + '-' + parts[1].padStart(2, '0')
}

// transforms a YYYY-MM-DD date to a MM/DD/YYYY date
export function convertHyphenDateToSlashDate(hyphenDate) {
  if (!hyphenDate) {
    return null
  }

  const parts = hyphenDate.split('-')

  return parts[1].padStart(2, '0') + '/' + parts[2].padStart(2, '0') + '/' + parts[0]
}

export function fetchMips(data, stringValue = true) {
  return getMips(data)
    .then((res) => {
      if (res) {
        // the code requires the option data to properly format saved, attested MIPS
        // stringValue lets us either return the full value object (for encounters) or the string value only (for notes)
        let fusionMIPS = res?.map((item) => ({
          ...item,
          value: stringValue ? item.value?.label || item.value : item?.option_data,
          // keeps MIPS object smaller
          rules: undefined,
        }))
        return fusionMIPS
      } else {
        console.error('Failed to load MIPS')
        return []
      }
    })
    .catch((e) => {
      console.error('Error loading MIPS', e)
      return []
    })
}
