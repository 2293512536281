import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getConversations,
  getCurrentUser,
  getPicture,
  getProviderConversations,
  getReadConversation,
  listContactList,
} from 'src/helper/backendHelper'

export const getMessageList = createAsyncThunk(
  //action type string
  'message/getContacts',
  // callback function
  async (data, thunkAPI) => {
    let tab = data?.tab
    let payload = {
      q: data?.q,
      page: data?.page,
      type: data?.type,
      currentUserId: data?.currentUserId,
    }

    if (tab === 'Archived') {
      let res = await getConversations(payload)

      if (res?.status === 400) {
        return thunkAPI.rejectWithValue('Server Error')
      } else {
        if (res?.status === 'success' || res?.status === 'failure') {
          if (res?.data) {
            for (let i = 0; i < res?.data.length; i++) {
              res.data[i] = processDateTime(res?.data[i])
            }
            res.data = messageListAvatar(res?.data, thunkAPI.getState()?.user?.user?._id)

            if (tab === 'Provider Hold') {
              return { data: res?.data, type: tab, status: res?.status }
            } else if (tab === 'Archived') {
              return { data: res?.data, type: tab, status: res?.status }
            }
          }
        } else {
          return thunkAPI.rejectWithValue('Server Error')
        }
      }
    } else if (tab === 'Provider Hold') {
      let res = await getProviderConversations(payload)

      if (res?.status === 400) {
        return thunkAPI.rejectWithValue('Server Error')
      } else {
        if (res?.status === 'success' || res?.status === 'failure') {
          if (res?.data) {
            for (let i = 0; i < res?.data.length; i++) {
              res.data[i] = processDateTime(res?.data[i])
            }
            res.data = messageListAvatar(res?.data, thunkAPI.getState()?.user?.user?._id)

            if (tab === 'Provider Hold') {
              return {
                data: res?.data,
                type: tab,
                status: res?.status,
              }
            }
          }
        } else {
          return thunkAPI.rejectWithValue('Server Error')
        }
      }
    }
  },
)

export const getContactList = createAsyncThunk(
  //action type string
  'message/getContactList',
  // callback function
  async (payload, thunkAPI) => {
    let res = await listContactList()

    if (res?.status === 400) {
      return thunkAPI.rejectWithValue('Server Error')
    } else {
      if (res?.status === 'success') {
        try {
          let currentUser = await getCurrentUser()

          for (let i = 0; i < currentUser?.message_favorites?.length; i++) {
            for (let j = 0; j < res?.data?.length; j++) {
              if (
                currentUser?.message_favorites[i]?.user_id === res?.data[j]?.user_id &&
                currentUser?.message_favorites[i]?.status === true
              ) {
                res.data[j] = { ...res?.data[j], favorites: true }
              }
            }
          }
        } catch (err) {
          console.log(err)
        }
        let practiceUser = res?.data?.filter((item) => {
          return item?.isInternal === false
        })
        let supportUser = res?.data?.filter((item) => {
          return item?.isInternal === true
        })
        let distributionGroup = groupBy(res?.data, 'user_title')
        return {
          data: res?.data,
          status: res?.status,
          practiceUser: practiceUser,
          supportUser: supportUser,
          distributionGroup: distributionGroup,
        }
      } else {
        return thunkAPI.rejectWithValue('Server Error')
      }
    }
  },
)

function groupBy(arr, criteria) {
  const newObj = arr.reduce(function (acc, currentValue) {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = []
    }
    acc[currentValue[criteria]].push(currentValue)
    return acc
  }, {})
  return newObj
}

const processDateTime = (item) => {
  if (item.conversations_type[0] != 'draft' && item.latest_message) {
    let comingDate = new Date(item.latest_message.message_datetime)
    if (new Date(comingDate).getFullYear() == new Date().getFullYear()) {
      if (new Date(comingDate).getMonth() + 1 == new Date().getMonth() + 1) {
        if (new Date(comingDate).getDate() == new Date().getDate()) {
          let ampm = comingDate.getHours() >= 12 ? 'pm' : 'am'
          item.latest_message.messageDay =
            comingDate.getHours() + ':' + comingDate.getMinutes() + ' ' + ampm // getSeconds
        } else if (new Date(comingDate).getDate() == new Date().getDate() - 1) {
          item.latest_message.messageDay = 'Yesterday'
        } else {
          item.latest_message.messageDay =
            new Date(comingDate).getMonth() +
            1 +
            '/' +
            new Date(comingDate).getDate() +
            '/' +
            new Date(comingDate).getFullYear()
        }
      } else {
        item.latest_message.messageDay =
          new Date(comingDate).getMonth() +
          1 +
          '/' +
          new Date(comingDate).getDate() +
          '/' +
          new Date(comingDate).getFullYear()
      }
    } else {
      item.latest_message.messageDay =
        new Date(comingDate).getMonth() +
        1 +
        '/' +
        new Date(comingDate).getDate() +
        '/' +
        new Date(comingDate).getFullYear()
    }
  }
  return item
}

export const loadMore = createAsyncThunk(
  //action type string
  'message/loadMore',
  // callback function
  async (payload, thunkAPI) => {
    let params = `?q=${payload?.q}&page=${payload?.page}&type=${payload?.type}`

    let res = await getConversations(payload)

    if (res?.status === 400) {
      return thunkAPI.rejectWithValue('Server Error')
    } else {
      if (res?.status === 'success' || res?.status === 'failure') {
        if (res?.data) {
          for (let i = 0; i < res?.data.length; i++) {
            res.data[i] = processDateTime(res?.data[i])
          }
          res.data = messageListAvatar(res?.data, thunkAPI.getState()?.user?.user?._id)

          if (payload?.type === 'messages') {
            return {
              data: res?.data,
              type: payload?.type,
              status: res?.status,
            }
            // return { data: res?.data, type: payload?.type, status: res?.status }
          } else if (payload?.type === 'archive') {
            return { data: res?.data, type: payload?.type, status: res?.status }
          }
        }
      } else {
        return thunkAPI.rejectWithValue('Server Error')
      }
    }
  },
)

const messageListAvatar = (data, currentUserId) => {
  let messageList = data
  messageList.map((conversation, t) => {
    if (conversation.participants) {
      let allAvatarDisplay = {}
      let isPracticeInvisibleExists = false
      conversation.participants = conversation.participants.filter((elem) => {
        if (elem.displayname) {
          return true
        }
      })
      conversation.participants.forEach((participant) => {
        if (!allAvatarDisplay[participant.user_id]) {
          if (participant.practice_visibility === true) {
            participant = avatarFormat(participant)
            allAvatarDisplay[participant.user_id] = participant
          } else if (!isPracticeInvisibleExists) {
            participant = avatarFormat(participant)
            allAvatarDisplay[participant.user_id] = participant
            if (participant.practice_visibility === false) {
              isPracticeInvisibleExists = true
            }
          }
        }
      })
      let avatar_participants = []
      let total = 2
      if (Object.keys(allAvatarDisplay).length < total) {
        total = Object.keys(allAvatarDisplay).length
      } else if (Object.keys(allAvatarDisplay).length == 2) {
        if (allAvatarDisplay[currentUserId]) {
          delete allAvatarDisplay[currentUserId]
          total = 1
        }
      } else {
        delete allAvatarDisplay[currentUserId]
      }
      let participants = Object.assign({}, allAvatarDisplay)
      conversation.partipantcount = Object.keys(allAvatarDisplay).length - 2
      for (let i = 0; i < total; i++) {
        if (conversation.message_users[i] && participants[conversation.message_users[i]]) {
          avatar_participants.push(participants[conversation.message_users[i]])
          delete participants[conversation.message_users[i]]
        } else {
          if (Object.keys(participants).length > 0) {
            let key = Object.keys(participants)[0]
            avatar_participants.push(participants[key])
            delete participants[key]
          }
        }
      }
      conversation.display_message_logo = avatar_participants
      return conversation
    }
  })
  return messageList
}
const avatarFormat = (participant) => {
  let inialDisplay = participant.displayname ? participant.displayname : ''
  let splitInitial = inialDisplay
    .split(' ')
    .map(function (getVal) {
      return getVal[0]
    })
    .join('')
  let displayLetters
  if (splitInitial.length > 2) {
    displayLetters = splitInitial.slice(-2)
  } else {
    displayLetters = splitInitial
  }
  participant['displayInitials'] = displayLetters
  participant['showIcon'] = participant.practice_visibility != true
  return participant
}

export const getChatMessages = createAsyncThunk(
  //action type string
  'message/getChatMessages',
  // callback function
  async (conversationId, thunkAPI) => {
    let res = await getReadConversation(conversationId?._id)

    if (res?.status === 400) {
      return thunkAPI.rejectWithValue('Server Error')
    } else {
      if (res?.status === 'success') {
        //participants avatar
        for (let i = 0; i < res?.data[0]?.participantss?.length; i++) {
          let participant = res?.data[0]?.participantss[i]
          if (participant?.username) {
            if (participant?.username.indexOf(' ') == -1) {
              participant.username_character = participant?.username?.charAt(0).toUpperCase()
            } else {
              let x = participant?.username.substr(participant?.username?.indexOf(' ') + 1)
              participant.username_character =
                participant?.username?.charAt(0).toUpperCase() + x.charAt(0).toUpperCase()
            }
          }
          if (participant?.practice_visibility == false) {
            participant.pv = 'assets/imgs/avatar-claimocity.png'
          }
        }
        //for type 2 message
        for (let i = 0; i < res?.data[0]?.messagess?.length; i++) {
          if (
            res?.data[0]?.messagess[i]?.message_type == 2 ||
            res?.data[0]?.messagess[i]?.message_type == 3
          ) {
            if (res?.data[0]?.messagess[i]?.file_name?.length > 0) {
              try {
                let result = await getPicture(res?.data[0]?.messagess[i]?.file_name)
                res.data[0].messagess[i]['fileurl'] = result?.data
              } catch (err) {}
            }
          }
        }
        //for type 3 message
        for (let i = 0; i < res?.data[0]?.messagess?.length; i++) {
          if (res?.data[0]?.messagess[i]?.message_type == 3) {
            res.data[0].messagess[i].playPauseAudio = false
          }
        }
        return { data: res?.data, status: res?.status }
      } else {
        return thunkAPI.rejectWithValue('Server Error')
      }
    }
  },
)
