import { getDemographics } from './demographicsThunk'
const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  ethnicityCodes: [],
  genderIdentityCodes: [],
  languageCodes: [],
  raceCodes: [],
  sexualOrientationCodes: [],
}

const demographicsSlice = createSlice({
  name: 'demographics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDemographics.fulfilled, (state, action) => {
      if (action.payload) {
        state.ethnicityCodes = action.payload.ethnicityCodes
        state.genderIdentityCodes = action.payload.genderIdentityCodes
        state.languageCodes = action.payload.languages
        state.raceCodes = action.payload.raceCodes
        state.sexualOrientationCodes = action.payload.sexualOrientationCodes
      }
    })
  },
})

const selectDemographics = (state) => state.demographics
const selectEthnicityCodes = (state) => state.demographics.ethnicityCodes
const selectGenderIdentityCodes = (state) => state.demographics.genderIdentityCodes
const selectLanguageCodes = (state) => state.demographics.languageCodes
const selectRaceCodes = (state) => state.demographics.raceCodes
const selectSexualOrientationCodes = (state) => state.demographics.sexualOrientationCodes

export {
  selectDemographics,
  selectEthnicityCodes,
  selectGenderIdentityCodes,
  selectLanguageCodes,
  selectRaceCodes,
  selectSexualOrientationCodes,
}

export default demographicsSlice.reducer
