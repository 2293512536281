import { createAsyncThunk } from '@reduxjs/toolkit'
import { getFromSettingsServer } from 'src/apiHelper/settingsApi'
import { getCollector } from '../../apiHelper/collectorApi'
import { getInpatientEncounter, processVisits } from '../../apiHelper/Dataformatter/util'
import { COLLECTOR_URL } from '../../apiHelper/apiRoute/collector'
import { SETTINGS_URL } from '../../apiHelper/apiRoute/settings'

export const getVisitsCensus = createAsyncThunk(
  'census/getVistByServiceDate',
  async (reqData, thunkAPI) => {
    try {
      const url = COLLECTOR_URL.CENSUS_LIST.replace('{serviceDate}', reqData.servicedate)
        .replace('{facility_id}', reqData.facility_id)
        .replace(
          '{showRecentlyDischarged}',
          thunkAPI.getState()?.user?.settings?.showRecentlyDischarged,
        )
      const diagUrl = SETTINGS_URL.FAV_DIAGNOSIS.replace('{searchString}', '')
      const cptSearchEndpoint = SETTINGS_URL.FAV_PROCEDURE.replace('{searchString}', '').replace(
        '{recordCount}',
        10,
      )
      const docByFacilityUrl = COLLECTOR_URL.GET_DOCUMENT_BY_FACILITY.replace(
        '{service_date}',
        reqData.servicedate,
      ).replace('{facilityIds}', [reqData.facility_id])
      const docByFacilityList = reqData.facility_id
        ? await getCollector(docByFacilityUrl)
        : { status: 'failed' }

      const visits = await getCollector(url)
      const promiseAll = await Promise.all([
        { resStatuses: await getFromSettingsServer(SETTINGS_URL.GET_VISIT_STATUS) },
        { diagnosisData: await getFromSettingsServer(diagUrl) },
        { procedureCodes: await getFromSettingsServer(cptSearchEndpoint) },
        { resVisits: visits },
        {
          docByFacilityList:
            docByFacilityList.status == 'success' ? docByFacilityList.result[0]?.files : [],
        },
      ])
      if (promiseAll[3].resVisits?.status === 500) {
        return 'Server Error'
      } else {
        if (promiseAll[3].resVisits?.status === 'success') {
          let finalList = {
            inPatients: [],
          }

          if (reqData?.thisProvider) {
            finalList = getInpatientEncounter(
              promiseAll[3].resVisits?.resData,
              promiseAll[0].resStatuses?.results?.[0].values,
              { showSmartCensus: thunkAPI.getState()?.user?.settings?.showSmartCensus },
            )
          }

          return {
            visitsByDate: promiseAll[3].resVisits?.resData,
            inPatients: processVisits(
              finalList?.inPatients,
              promiseAll[0].resStatuses?.results?.[0].values,
              { showSmartCensus: thunkAPI?.getState()?.user?.settings?.showSmartCensus },
            ),
            ...finalList,
            visitStatuses: promiseAll[0]?.resStatuses?.results?.[0].values,
            diagnosisList: promiseAll[1].diagnosisData.results,
            diagnosisTotalCount: promiseAll[1].diagnosisData.total,
            procedureCodes: promiseAll[2].procedureCodes.results,
            docByFacilityList: promiseAll[4].docByFacilityList,
          }
        }
      }
    } catch (err) {
      console.log('getting error in rendering census', err)
      return false
    }
  },
)
export const getBetaVisitStatus = createAsyncThunk('census/getBetaVisitStatus', async () => {
  try {
    const resStatuses = await getFromSettingsServer(SETTINGS_URL.GET_VISIT_STATUS)
    // console.log(resStatuses, 'getBetaVisitStatus')
    if (resStatuses?.status === 500) {
      return 'Server Error'
    } else {
      if (resStatuses?.status === 'success') {
        return {
          visitStatuses: resStatuses,
        }
      }
    }
  } catch (err) {
    console.log('getting error in getBetaVisitStatus', err)
    return false
  }
})
export const getDischargeCodes = createAsyncThunk('census/getDischargeCodes', async () => {
  try {
    const resStatuses = await getFromSettingsServer(SETTINGS_URL.GET_DISCHARGE_CODES)
    // console.log(resStatuses, 'getDischargeCodes')
    if (resStatuses?.status === 500) {
      return 'Server Error'
    } else {
      if (resStatuses?.status === 'success') {
        return {
          dischargeCodes: resStatuses,
        }
      }
    }
  } catch (err) {
    console.log('getting error in getDischargeCodes', err)
    return false
  }
})

export const getDocuments = createAsyncThunk('census/getDocuments', async (reqData) => {
  try {
    const docByFacilityUrl = COLLECTOR_URL.GET_DOCUMENT_BY_FACILITY.replace(
      '{service_date}',
      reqData.servicedate,
    ).replace('{facilityIds}', [reqData.facility_id])
    const docByFacilityList = await getCollector(docByFacilityUrl)

    if (docByFacilityList?.status === 500) {
      return 'Server Error'
    } else {
      if (docByFacilityList?.status === 'success') {
        return {
          docByFacilityList:
            docByFacilityList.status == 'success' ? docByFacilityList.result[0]?.files : [],
        }
      }
    }
  } catch (err) {
    console.log('getting error in rendering census', err)
    return false
  }
})

export const getFacilities = createAsyncThunk('census/getFacilities', async () => {
  try {
    const resFacilities = await getFromSettingsServer(SETTINGS_URL.FACILITIES_BY_PROVIDER)
    if (resFacilities?.status === 500) {
      return 'Server Error'
    } else {
      if (resFacilities?.status === 'success') {
        return {
          allFacilitiesList: resFacilities.results.filter((fac) => fac.enabled),
        }
      }
    }
  } catch (err) {
    console.log('getting error in getFacilities', err)
    return false
  }
})

// TODO - why is this identical to a call in getVisitsCensus
export const getVisitsList = createAsyncThunk('census/getVisitsList', async (reqData, thunkAPI) => {
  try {
    const url = COLLECTOR_URL.CENSUS_LIST.replace('{serviceDate}', reqData.servicedate)
      .replace('{facility_id}', reqData.facility_id)
      .replace(
        '{showRecentlyDischarged}',
        thunkAPI.getState()?.user?.settings?.showRecentlyDischarged,
      )
    const resVisits = await getCollector(url)
    const docByFacilityUrl = COLLECTOR_URL.GET_DOCUMENT_BY_FACILITY.replace(
      '{service_date}',
      reqData.servicedate,
    ).replace('{facilityIds}', [reqData.facility_id])
    const docByFacilityList = await getCollector(docByFacilityUrl)
    if (resVisits?.status === 500) {
      return 'Server Error'
    } else {
      if (resVisits?.status === 'success') {
        return {
          visitsByDate: resVisits?.resData,
          docByFacilityList:
            docByFacilityList.status == 'success' ? docByFacilityList.result[0]?.files : [],
        }
      }
    }
  } catch (err) {
    console.log('getting error in rendering census', err)
    return false
  }
})

export const cptSearch = createAsyncThunk('census/cptSearch', async (reqData) => {
  try {
    let cptSearchEndpoint = (reqData.searchString ? SETTINGS_URL.GET_CPT : SETTINGS_URL.GET_CPT_POS)
      .replace('{searchString}', reqData.searchString || '')
      .replace('{recordCount}', 10)
      .replace('{pos}', reqData.pos ? reqData.pos : '')

    cptSearchEndpoint += `&isInitial=${String(Boolean(reqData.isInitial))}`

    let cptSearchResult = await getFromSettingsServer(cptSearchEndpoint)
    if (cptSearchResult?.status === 500) {
      return 'Server Error'
    } else {
      if (cptSearchResult?.status === 'success') {
        return cptSearchResult.results ? cptSearchResult.results : []
      }
    }
  } catch (err) {
    console.log('getting error in rendering cptSearch', err)
    return false
  }
})

export const diagnosisSearch = createAsyncThunk('census/diagnosisSearch', async (reqData) => {
  try {
    const diagnosisSearchEndpoint = reqData.searchString
      ? SETTINGS_URL.GET_DIAGNOSIS.replace('{searchString}', reqData.searchString)
      : SETTINGS_URL.FAV_DIAGNOSIS.replace('{searchString}', '').replace('{recordCount}', '10')

    let diagnosisSearchResult = await getFromSettingsServer(diagnosisSearchEndpoint)
    if (diagnosisSearchResult?.status === 500) {
      return 'Server Error'
    } else {
      if (diagnosisSearchResult?.status === 'success') {
        return diagnosisSearchResult.results ? diagnosisSearchResult.results : []
      }
    }
  } catch (err) {
    console.log('getting error in rendering diagnosisSearch', err)
    return false
  }
})

export const getDiagnosisByVisitId = createAsyncThunk(
  'census/getDiagnosisByVisitId',
  async (visitId) => {
    try {
      const endpoint = COLLECTOR_URL.GET_DIAGNOSIS_BY_VISIT_ID.replace('{visitId}', visitId)
      let diagnosisSearchResult = await getCollector(endpoint)
      if (diagnosisSearchResult?.status === 500) {
        return 'Server Error'
      } else {
        if (diagnosisSearchResult?.status === 'success') {
          return diagnosisSearchResult.results ? diagnosisSearchResult.results : []
        }
      }
    } catch (err) {
      console.log('getting error in rendering getDiagnosisByVisitId', err)
      return false
    }
  },
)

export const getCptByVisitId = createAsyncThunk('census/getCptByVisitId', async (visitId) => {
  try {
    const endpoint = COLLECTOR_URL.GET_CPT_BY_VISIT_ID.replace('{visitId}', visitId)
    let cptSearchResult = await getCollector(endpoint)
    if (cptSearchResult?.status === 500) {
      return 'Server Error'
    } else {
      if (cptSearchResult?.status === 'success') {
        return cptSearchResult.results ? cptSearchResult.results : []
      }
    }
  } catch (err) {
    console.log('getting error in rendering getCptByVisitId', err)
    return false
  }
})
