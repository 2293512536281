import { createAsyncThunk } from '@reduxjs/toolkit'
import { postCollector } from 'src/apiHelper/collectorApi'
import { COLLECTOR_URL } from '../../apiHelper/apiRoute/collector'

export const verifyUsername = createAsyncThunk(
  //action type string
  'forgotPassword/verifyUsername',
  // callback function
  async (data, thunkAPI) => {
    const verifyUsernameRes = await postCollector(COLLECTOR_URL.RESET_OTP_SEND, data)

    if (verifyUsernameRes?.status === 500) {
      return thunkAPI.rejectWithValue('Server Error')
    } else {
      if (
        verifyUsernameRes?.userNotFind ||
        verifyUsernameRes?.status === 'failure' ||
        verifyUsernameRes?.name === 'ValidationError'
      ) {
        // return thunkAPI.rejectWithValue('Username Not Found')
      } else {
        return { ...verifyUsernameRes, username: data.username }
      }
    }
  },
)

export const verifyForgotpwdOtp = createAsyncThunk(
  //action type string
  'forgotPassword/verifyForgotpwdOtp',
  // callback function
  async (data, thunkAPI) => {
    const verifyOtpRes = await postCollector(COLLECTOR_URL.RESET_OTP_VALIDATE, data)

    if (verifyOtpRes?.status === 500) {
      return thunkAPI.rejectWithValue('Server Error')
    } else {
      if (verifyOtpRes?.status !== 'failure') {
        return { ...verifyOtpRes, otp: data.otp }
      } else {
        return thunkAPI.rejectWithValue('Username Not Found')
      }
    }
  },
)
