import { createSlice } from '@reduxjs/toolkit'
import {
  login,
  mfaLogin,
  providerLogin,
  rememberAuth,
  userSettingGet,
  userSettingPut,
  getProvider,
} from './loginThunk'

const initialState = {
  authToken: null,
  authRefreshToken: null,
  mfatoken: null,
  isMFAEnabled: false,
  secure_code: null,
  otp: null,
  otpType: null,
  data: [],
  status: 'idle',
  isError: false,
  errorMsg: '',
  practice: null,
  provider: null,
  practiceList: [],
  providerList: [],
  isloading: false,
  isProviderLogin: false,
  roles: null,
  permissions: null,
  settings: null,
  features: null,
  user: null,
}

const loginSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProvider: (state, action) => {
      state.provider = action.payload
    },
    setPractice: (state, action) => {
      state.practice = action.payload
    },
    userLogout: (state) => {
      localStorage.clear()
      state.authToken = initialState.authToken
      state.authRefreshToken = initialState.authRefreshToken
      state.otp = initialState.otp
      state.data = initialState.data
      state.status = initialState.status
      state.practice = initialState.practice
      state.provider = initialState.provider
      state.practiceList = initialState.practiceList
      state.providerList = initialState.providerList
      state.isProviderLogin = initialState.isProviderLogin
      state.roles = initialState.roles
      state.permissions = initialState.permissions
      state.settings = initialState.settings
      state.features = initialState.features
      state.user = initialState.user
      state.census = initialState.census
      window.location.replace('/')
    },
    closeError: (state) => {
      state.isError = false
      state.errorMsg = ''
    },
    clearMfa: (state) => {
      state.isMFAEnabled = initialState?.isMFAEnabled
      state.mfatoken = initialState?.mfatoken
      state.secure_code = initialState?.secure_code
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isloading = false

        if (action.payload?.isMFAEnabled) {
          state.isMFAEnabled = action.payload?.isMFAEnabled
          state.mfatoken = action.payload?.mfatoken
          state.secure_code = action.payload?.secure_code
          state.username = action.payload?.username
          state.otpType = action.payload?.['otp-type']
          state.user = action.payload?.user
        } else {
          state.authToken = action.payload?.authToken ? action.payload?.authToken : null
          state.authRefreshToken = action.payload?.authRefreshToken
          state.user = action.payload?.user
          state.roles = action.payload?.roles
          state.permissions = action.payload?.permissions
          state.otp = action.payload?.passwordIsOtp
          state.providerList = action.payload?.providerList
          state.practiceList = action.payload?.practiceList?.filter((item, i) => {
            return item?.enabled === true
          })
          if (state.practiceList?.length === 1) {
            state.practice = state.practiceList[0]
            let filteredProviders = state.providerList.filter((item, i) => {
              return item.practice_id === state.practice?.practice_id
            })
            if (filteredProviders?.length === 1) {
              state.provider = filteredProviders[0]
            } else if (filteredProviders?.length === 0) {
              state.provider = null
            }
          }
        }
      })
      .addCase(getProvider.fulfilled, (state, action) => {
        if (action.payload) {
          state.provider = action.payload
        }
      })
      .addCase(mfaLogin.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(mfaLogin.fulfilled, (state, action) => {
        state.isloading = false
        state.authToken = action.payload?.authToken ? action.payload?.authToken : null
        state.authRefreshToken = action.payload?.authRefreshToken
        state.user = action.payload?.user
        state.roles = action.payload?.roles
        state.permissions = action.payload?.permissions
        state.otp = action.payload?.passwordIsOtp
        state.providerList = action.payload?.providerList
        state.practiceList = action.payload?.practiceList?.filter((item, i) => {
          return item?.enabled === true
        })
        if (state.practiceList?.length === 1) {
          state.practice = state.practiceList[0]
          let filteredProviders = state.providerList.filter((item, i) => {
            return item.practice_id === state.practice?.practice_id
          })
          if (filteredProviders?.length === 1) {
            state.provider = filteredProviders[0]
          } else if (filteredProviders?.length === 0) {
            state.provider = null
          }
        }
      })
      .addCase(mfaLogin.rejected, (state, action) => {
        state.isloading = false
        state.isError = true

        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(providerLogin.pending, (state, action) => {
        state.isloading = true
        state.settings = initialState.settings
      })
      .addCase(providerLogin.fulfilled, (state, action) => {
        state.isloading = false
        state.authToken = action.payload?.authToken
        state.provider = action.payload?.providerRes
        state.practice = action.payload?.practiceRes
        state.isProviderLogin = true
        state.roles = action.payload?.rolesRes
        state.settings = action.payload?.settingsRes
        state.features = action.payload?.featuresRes
        state.permissions = action.payload?.permissionsRes
        if (action.payload?.settingsRes?.last_favorite_id) {
          window.localStorage.setItem('actTab', action.payload?.settingsRes?.last_favorite_id)
        }
      })
      .addCase(providerLogin.rejected, (state, action) => {
        state.isloading = false
        state.isError = true

        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(userSettingGet.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(userSettingGet.fulfilled, (state, action) => {
        state.isloading = false
        state.settings = action.payload?.settingsRes
      })
      .addCase(userSettingGet.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(userSettingPut.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(userSettingPut.fulfilled, (state, action) => {
        state.isloading = false
        if (action.payload) {
          state.settings = action.payload
        }
      })
      .addCase(userSettingPut.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(rememberAuth.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(rememberAuth.fulfilled, (state, action) => {
        state.isloading = false
        state.authToken = action.payload?.authToken
        state.authRefreshToken = action.payload?.authRefreshToken
        state.brand = action.payload?.brand
        state.user = action.payload?.user
        state.roles = action.payload?.roles
        state.permissions = action.payload?.permissions
        state.otp = action.payload?.passwordIsOtp
        state.providerList = action.payload?.providerRes
        state.practiceList = action.payload?.practiceRes
        state.provider = action.payload?.currentProviderRes
        state.practice = action.payload?.currentPracticeRes
        state.roles = action.payload?.rolesRes
        state.settings = action.payload?.settingsRes
        state.features = action.payload?.featuresRes
        state.permissions = action.payload?.permissionsRes
      })
      .addCase(rememberAuth.rejected, (state, action) => {
        state.isloading = false
        state.isError = true

        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
        loginSlice.caseReducers.userLogout(state)
      })
  },
})
export const { setPractice, setProvider, userLogout } = loginSlice.actions
export const selectUser = (state) => state.user
export default loginSlice.reducer
