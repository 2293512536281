export const SETTINGS_URL = {
  FACILITIES_BY_PROVIDER: 'currentprovider/facilities?enabled=true',
  GET_PRACTICE: 'currentuser/practices?enabled=true&pagesize=10000000000',
  GET_PROVIDER: 'currentuser/providers',
  CURRENT_PROVIDER: 'currentprovider',
  CURRENT_PRACTICE: 'currentpractice',
  USER_SETTINGS: 'currentuser/settings',
  USER_ROLE: 'currentuser/roles',
  USER_PERMISSION: 'currentuser/permissions',
  USER_FEATURE: 'currentuser/features',
  GET_VISIT_STATUS: 'globaldata/visit_statuses_beta',
  GET_DISCHARGE_CODES: 'globaldata/discharge_codes',
  GET_FACILITY_LIST: 'currentprovider/v2/facilities?enabled=true',
  GET_DIAGNOSIS: 'currentprovider/diagnosis?q={searchString}&startIndex=0&recordCount=100',
  GET_CPT:
    'currentprovider/procedures?q={searchString}&place_of_service={pos}&startIndex=0&recordCount={recordCount}',
  GET_CPT_POS:
    'currentprovider/favprocedures?q={searchString}&place_of_service={pos}&startIndex=0&recordCount={recordCount}',
  GET_PROGRESSNOTE_TEMPLATES: 'currentpractice/medicalcharttemplates?&enabled=true',
  FAV_PROCEDURE: 'currentprovider/favprocedures/',
  FAV_DIAGNOSIS: 'currentprovider/favdiagnosis',

  FAV_PROCEDURES: 'currentprovider/favprocedures?q={code} &place_of_service={place_of_service}',
  GET_CENSUS_VIEW: 'currentuser/censusview',
  PUT_CENSUS_VIEW: 'currentuser/censusview',
  GROUP_FACILITIES: 'currentprovider/groupfacilities',
  GET_PICKLIST: 'procedurecodes/procedure_modifiers?pagesize=500&page=0',
  GET_CODETEMPLATES: 'currentpractice/codetemplates',
  DEMOGRAPHICS: 'demographics',
  DIRECTIVES_CHART_VALIDATION: 'directives/chart_validation',
}
