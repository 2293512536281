import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCollector } from '../../apiHelper/collectorApi'
import { getInpatientEncounter } from '../../apiHelper/Dataformatter/util'
import { getFromSettingsServer } from 'src/apiHelper/settingsApi'
import { COLLECTOR_URL } from '../../apiHelper/apiRoute/collector'
import { SETTINGS_URL } from '../../apiHelper/apiRoute/settings'

export const getProviderHoldList = createAsyncThunk(
  'providerhold/list',
  async ({ reqData, thunkAPI, activeButton = 'provider' }) => {
    try {
      let startIndex = 0,
        recordCount = 11500,
        scope = activeButton.toLowerCase()
      // 'practice'
      const url = COLLECTOR_URL.GET_PROVIDER_HOLD_DATA.replace('{startIndex}', `${startIndex}`)
        .replace('{recordCount}', `${recordCount}`)
        .replace('{scope}', `${scope}`)
      const resLists = await getCollector(url)
      const rolesRes = await getFromSettingsServer(SETTINGS_URL.USER_ROLE)
      if (resLists?.status === 500) {
        return 'Server Error'
      } else {
        if (resLists?.status === 'SUCCESS') {
          let finalList = {}
          if (reqData?.thisProvider) {
            // TODO statuses is broken
            finalList = getInpatientEncounter(
              resLists?.data?.result,
              {},
              { showSmartCensus: thunkAPI.getState()?.user?.settings?.showSmartCensus },
            )
          }
          // console.log('prodata', resLists?.data?.result, finalList)
          let arr = resLists?.data?.result
          for (let i = 0; i < arr?.length; i++) {
            if (arr[i]?.hold_date?.statuses?.length == 0) {
              arr[i].provider_hold_date = arr[i]?.hold_date?.last_modified_date
            } else {
              const datetimes = arr[i]?.hold_date?.statuses?.map((item) => {
                return item?.status_date
              })
              // console.log('datetimes', datetimes)
              let maxDatetimeObj = datetimes[0] // start with the first object as the max datetime

              for (let i = 1; i < datetimes.length; i++) {
                const currentTimestamp = Date.parse(datetimes[i])
                const maxTimestamp = Date.parse(maxDatetimeObj)
                if (currentTimestamp > maxTimestamp) {
                  maxDatetimeObj = datetimes[i]
                }
              }

              // console.log('maxDatetimeObj', maxDatetimeObj)
              arr[i].provider_hold_date = maxDatetimeObj
            }
          }
          // console.log('arrss', arr)
          finalList.inPatients = arr
          return {
            visitsByDate: resLists?.data?.result,
            ...finalList,
            userRoles:
              rolesRes?.status === 'success' ? rolesRes?.results?.map((x) => x.groupname) : [],
          }
        }
      }
    } catch (err) {
      console.log('getting error in rendering census', err)
      return false
    }
  },
)
