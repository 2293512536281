import axios from 'axios'
import { setupInterceptorsTo } from './helper.ts'

const socketAPI = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_SOCKET_BASEURL,
    headers: {
      'Content-Type': 'application/json',
      // Origin: "claimocity",
    },
  }),
)

export async function getFromSocketServer(url, config = {}) {
  return await socketAPI.get(url, { ...config }).then((response) => response.data)
}

export async function postToSocketServer(url, data, config = {}) {
  return socketAPI.post(url, { ...data }, { ...config }).then((response) => response.data)
}

export async function putDataToSocketServer(url, data, config = {}) {
  return socketAPI.put(url, { ...data }, { ...config }).then((response) => response.data)
}
