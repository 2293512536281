import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import '../src/apiHelper/apiRoute/collector'
import '../src/apiHelper/apiRoute/settings'
import { useDispatch } from 'react-redux'
import { userLogout } from './features/login/loginSlice'
import { useSelector } from 'react-redux'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { rememberAuth } from './features/login/loginThunk'
import { setIsSaveDraft } from './features/progressNote/progressNoteSlice'
import { CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react-pro'
import Button from 'src/components/common/Button/Button'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))

export default function App() {
  const dispatch = useDispatch()
  const [AuthToken] = useLocalStorage('authUser')

  const authToken = useSelector((state) => state.user.authToken)
  const theme = useSelector((state) => state.layout.theme)

  const [showTimeoutModal, setShowTimeoutModal] = useState(false)
  const [mins, setMins] = useState(2)
  const [secs, setSecs] = useState(0)

  const logout = async () => {
    await dispatch(userLogout())
  }

  // Please Don't Touch this useEffect its main functionality for page refresh so don't touch it please
  useEffect(() => {
    if (AuthToken?.authToken) {
      dispatch(rememberAuth(AuthToken))
    }
  }, [dispatch, AuthToken])

  useEffect(() => {
    const updateLastActivity = () => localStorage.setItem('lastActivity', new Date())
    let timeInterval = ''

    if (authToken) {
      document.addEventListener('mousemove', updateLastActivity)
      document.addEventListener('click', updateLastActivity)

      timeInterval = setInterval(() => {
        const NOW = new Date()
        let lastActivity = localStorage.getItem('lastActivity') || NOW
        let diffMs = Math.abs(new Date(lastActivity) - NOW) // milliseconds between now & last activity
        let seconds = Math.floor(diffMs / 1000)
        let minute = Math.floor(seconds / 60)

        if (lastActivity) {
          if (minute === 18 && !showTimeoutModal) {
            setShowTimeoutModal(true)
          } else if (minute >= 20) {
            if (window.location.href.includes('progressnote')) {
              dispatch(setIsSaveDraft(true))
              setTimeout(() => {
                clearInterval(timeInterval)
                logout()
              }, 10000)
            } else {
              clearInterval(timeInterval)
              logout()
            }
          }
        }
      }, 1000)
    }

    return () => {
      document.removeEventListener('mousemove', updateLastActivity)
      document.removeEventListener('click', updateLastActivity)
      clearInterval(timeInterval)
    }
  }, [dispatch, authToken])

  useEffect(() => {
    theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
  }, [theme])

  useEffect(() => {
    if (showTimeoutModal) {
      const myInterval = setInterval(() => {
        if (secs > 0) {
          setSecs(secs - 1)
        }
        if (secs === 0) {
          if (mins === 0) {
            clearInterval(myInterval)
          } else {
            setMins(mins - 1)
            setSecs(59)
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval)
      }
    }
  }, [mins, secs, showTimeoutModal])

  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme="colored"
        transition={Slide}
        icon={false}
      />
      <CModal
        alignment="center"
        visible={showTimeoutModal}
        onClose={() => setShowTimeoutModal(false)}
      >
        <CModalHeader onClose={() => setShowTimeoutModal(false)}>
          <CModalTitle>
            <div>Session Timeout Warning</div>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          {mins === 0 && secs === 0 ? null : (
            <h6>
              {mins}:{secs < 10 ? `0${secs}` : secs} Until Timeout
            </h6>
          )}
        </CModalBody>
        <CModalFooter>
          <Button color="secondary" variant="fullwidth" compressed onClick={() => logout()}>
            Logout
          </Button>
          <Button
            color="primary"
            variant="fullwidth"
            compressed
            onClick={() => {
              // the click will reset the lastActivity timer
              // so we just hide the modal again
              setShowTimeoutModal(false)
              setMins(2)
              setSecs(0)
            }}
          >
            Extend
          </Button>
        </CModalFooter>
      </CModal>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  )
}
