import { getChartData, getInitialPayload } from './progressNoteThunk'
import { chartArrayGenerater } from 'src/helper/formater'
import { maintainDxOptionData } from '../../components/common/shared/sharedDXLogic'

const { createSlice, current } = require('@reduxjs/toolkit')

const initialState = {
  isloading: true,
  isError: false,
  errorMsg: '',
  isHaveChart: false,
  thisPatient: null,
  progressImg: null,
  medicalchartTemplates: null,
  selectedTemplate: null,
  diagnosisCodesSelected: [],
  procedureCodesSelected: [],
  visitDetails: null,
  mips: [],
  isInputDisable: false,
  isValidate: true,
  selectedSections: [],
  activeSection: undefined,
  selectedSnippet: [],
  diagnosisList: [],
  procedureCodes: [],
  cursorIndex: -1,
  isSaveDraft: false,
}

const careCoordStatement = `This patient qualifies for Chronic Care Management \
by having 2+ chronic care diagnoses. I have reviewed the Care Coordination program with \
the patient and the patient has verbally consented to enrolling in the Care Coordination program post discharge.`

const progressNoteSlice = createSlice({
  name: 'progressNote',
  initialState,
  reducers: {
    changeMIPSdata: (state, action) => {
      state.selectedTemplate = setMIPSdata(state?.selectedTemplate, action.payload)
      state.mips = action.payload
      state.selectedSections = updateSelectedSectionMips(
        state.selectedSections,
        action.payload,
        state.selectedTemplate,
      )
    },
    changeCursor: (state, action) => {
      state.cursorIndex = action.payload
    },
    updateChartErrors: (state, action) => {
      if (action.payload) {
        const errors = action.payload

        const oldSelectedStates = [...state.selectedSections]
        oldSelectedStates.forEach((section) => {
          // always clear the errors each time, so we can add the new errors
          delete section.errors

          // if an error object exists for the section, add it
          let errorSection = errors.find((errSection) => errSection?.title === section?.title)
          if (errorSection) {
            section.errors = errorSection.errors
          }
        })

        state.selectedSections = oldSelectedStates
      }
    },
    setSelectedTemplate: (state, action) => {
      let newTemplateData = action.payload?.data?.map((item) => {
        if (item?.title === 'MIPS' && item?.sections?.length < 1) {
          return { ...item, sections: state.mips }
        } else {
          return item
        }
      })

      state.selectedTemplate = { ...action.payload, data: newTemplateData }
    },
    setIsSaveDraft: (state, action) => {
      state.isSaveDraft = action.payload
    },
    setNoteValidation: (state, action) => {
      state.isValidate = action.payload
    },
    initiateDiagnosisPlan: (state, action) => {
      let { template, section } = diagnosisPlanOptionSet(
        state.selectedTemplate,
        action.payload,
        state.selectedSections,
        true,
      )

      state.selectedTemplate = template
      state.selectedSections = section
    },
    setNoteSectionValue: (state, action) => {
      let thisSection = state.selectedSections?.find(
        (item) => item?.title === state.activeSection?.title,
      )

      if (thisSection) {
        let isHaveOnlyTextarea = checkItsHaveOnlyTextArea(thisSection)

        state.selectedSections = onSnippetFillHandler(state, action.payload)
        if (isHaveOnlyTextarea) {
          state.selectedTemplate = extractTextAreaValue(state, action.payload)
        }

        state.selectedTemplate = setDisableField(state, action.payload)
      }
    },

    setProcedureCode: (state, action) => {
      state.procedureCodesSelected = action.payload?.filter(
        (item) => item?.code && item?.code !== '',
      )
    },
    setSelectedSections: (state, action) => {
      // if the section is skipped, it is unselected, and shouldn't start as a default selected section
      // (really hoping this isn't too strong of a filter)
      state.selectedSections = action.payload.filter((section) => !section?.skip)
    },
    setActiveSection: (state, action) => {
      state.activeSection = action.payload
    },
    onFirstNoteLoad: (state, action) => {
      if (!state.selectedTemplate) {
        return
      }

      // here we would want to try to add the logic to update the left side (if its not a disabled note)
      // with new dx values from the encounter

      // or make a function to figure out how to update the left side when only parts of it are disabled
      state.selectedSections = action.payload

      // update care_coord once selected sections are loaded
      progressNoteSlice.caseReducers.setCheckCareCoordination(state)
    },
    setDiagnosisCode: (state, action) => {
      state.diagnosisCodesSelected = action.payload
    },

    onTemplateValueSet: (state, action) => {
      let tempList = state?.selectedSections

      let index = tempList.findIndex((item) => item.title === state?.activeSection?.title)

      let newValue = action.payload
        ? Object.values(action.payload)
            ?.filter((item) => item)
            ?.join(',\n')
        : null

      let finalText = tempList[index]?.typedValue
        ? `${tempList[index]?.typedValue}\n${newValue}`
        : newValue

      tempList[index] = {
        ...tempList[index],
        values: [finalText],
        templatedValue: newValue,
        isTemplate: true,
      }

      state.selectedSections = tempList
    },
    onSnippetAdd: (state, action) => {
      let { selectedSections, cursorIndex } = onSnippetAddHandler(state, action.payload)
      state.selectedSections = selectedSections
      state.cursorIndex = cursorIndex
      state.selectedSnippet = []
    },
    onDotPhraseSnippetAdd: (state, action) => {
      const { snippetText, startingDot } = action.payload
      let { selectedSections, cursorIndex } = onSnippetAddHandler(state, [snippetText], startingDot)
      state.selectedSections = selectedSections
      state.cursorIndex = cursorIndex
      state.selectedSnippet = []
    },
    resetProgressNote: (state) => {
      return { ...state, ...initialState }
    },
    setCheckCareCoordination: (state, action) => {
      const showCareCoord = action?.payload ?? state?.visitDetails?.care_coordination

      // update selectedTemplate
      if (state?.selectedTemplate?.data) {
        let data = [...state?.selectedTemplate?.data]
        let careCoordIndex = data?.findIndex((item) => item?.title === 'Care Coordination')

        if (careCoordIndex >= 0 && data[careCoordIndex]) {
          let careCoordData = data[careCoordIndex]
          data[careCoordIndex] = {
            ...careCoordData,
            sections: careCoordData.sections.map((section, sectionIndex) => ({
              ...section,
              options: section.options.map((option, optionIndex) =>
                optionIndex === 0
                  ? { ...option, isChecked: showCareCoord, value: showCareCoord }
                  : option,
              ),
            })),
            values: [showCareCoord ? careCoordStatement : ''],
            skip: !showCareCoord,
          }
        }

        state.selectedTemplate = { ...state.selectedTemplate, data }
      }

      // update selectedSections
      if (state.selectedSections) {
        let sections = [...state.selectedSections]
        let careCoordSelectedIndex = sections?.findIndex(
          (item) => item?.title === 'Care Coordination',
        )
        if (careCoordSelectedIndex >= 0 && sections[careCoordSelectedIndex]) {
          let careCoordSelectedData = sections[careCoordSelectedIndex]

          sections[careCoordSelectedIndex] = {
            ...careCoordSelectedData,
            sections: careCoordSelectedData.sections.map((section, sectionIndex) => ({
              ...section,
              options: section.options.map((option, optionIndex) =>
                optionIndex === 0
                  ? { ...option, isChecked: showCareCoord, value: showCareCoord }
                  : option,
              ),
            })),
            values: [showCareCoord ? careCoordStatement : ''],
          }
        } else if (showCareCoord) {
          let careCoordSection = state?.selectedTemplate?.data?.find(
            (item) => item?.title === 'Care Coordination',
          )
          if (careCoordSection) {
            sections = [...sections, { ...careCoordSection, skip: false }]
          }
        }

        state.selectedSections = sections
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialPayload.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getInitialPayload.fulfilled, (state, action) => {
        state.isloading = false
        state.progressImg = action.payload?.progressImg || null
        state.diagnosisCodesSelected = action.payload?.diagnosisData || []
        state.procedureCodesSelected = action.payload?.proceduresData || []
        state.visitDetails = action.payload?.visitDetail || null

        state.diagnosisList = action.payload?.diagnosisList || []
        state.procedureCodes = action.payload?.procedureList || []

        state.medicalchartTemplates = action.payload?.templates
        state.mips = action.payload?.mips

        if (action.payload?.chartData) {
          state.isHaveChart = true
          state.selectedSnippet = getInitialSelectedSnippet(action.payload?.chartData?.data)

          state.isInputDisable = Boolean(action.payload?.chartData?.signed_on)
          let thisTemp = action.payload?.templates?.find(
            (item) => item.template === action.payload?.chartData?.template,
          )
          if (thisTemp) {
            if (action.payload?.mips) {
              state.selectedTemplate = setMIPSdata(
                {
                  ...thisTemp,
                  data: action.payload?.chartData?.data,
                },
                action.payload?.mips,
              )
            } else {
              state.selectedTemplate = {
                ...thisTemp,
                data: action.payload?.chartData?.data,
              }
            }
          } else {
            if (action.payload?.mips) {
              state.selectedTemplate = setMIPSdata(action.payload?.chartData, action.payload?.mips)
            } else {
              state.selectedTemplate = action.payload?.chartData
            }
          }
        }

        state.isloading = false
      })
      .addCase(getInitialPayload.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })

      .addCase(getChartData.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getChartData.fulfilled, (state, action) => {
        state.isloading = false
        state.progressImg = action.payload?.progressImg ? action.payload?.progressImg : null
      })
      .addCase(getChartData.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
  },
})

const onSnippetAddHandler = (state, data, offsetStart = null) => {
  let tempList = state?.selectedSections

  let index = tempList.findIndex((item) => item.title === state?.activeSection?.title)

  let valueList = data

  let oldValue = tempList[index]?.values
    ? Object.values([...tempList[index]?.values])?.join(',\n')
    : ''
  let newValue = Object.values(valueList)?.join(', \n') + ' '

  let strOffset = state?.cursorIndex
  if (offsetStart) {
    // if dot phrase adding a snippet, subtract 1 (for the dot) and use the offset instead of cursor index
    strOffset = offsetStart - 1
  }

  tempList[index] = {
    ...tempList[index],
    values: [oldValue.slice(0, strOffset) + newValue + oldValue.slice(state?.cursorIndex)],
    isTemplate: false,
  }

  return {
    selectedSections: tempList,
    cursorIndex: newValue?.length + strOffset,
  }
}

const onSnippetFillHandler = (state, data) => {
  let tempList = state?.selectedSections

  let index = tempList.findIndex((item) => item.title === state?.activeSection?.title)

  tempList[index] = {
    ...tempList[index],
    values: [data],
    typedValue: data,
    isTemplate: false,
  }

  return tempList
}

const getInitialSelectedSnippet = (chartData) => {
  let sectionHasSnippet = chartData?.filter((item) => item?.selected_snippet?.length > 0)

  let selected_snippets = []

  sectionHasSnippet?.map?.((item) => {
    item?.selected_snippet?.map((snip) => {
      selected_snippets.push(snip?.snippets)
    })
  })

  return selected_snippets
}

const diagnosisPlanOptionSet = (template, dxCodes, selectedSections, isTemplate = true) => {
  let tempData = { ...template }
  let dataList = [...tempData?.data]

  let index =
    dataList?.findIndex((item) =>
      item?.sections?.find((it) => it?.type === 'diagnosisPlan' || it?.type === 'diagnosis'),
    ) ?? -1

  let sectionIndex =
    dataList?.[index]?.sections?.findIndex(
      (item) => item?.type === 'diagnosisPlan' || item?.type === 'diagnosis',
    ) ?? -1

  if (index > -1 && sectionIndex > -1) {
    dataList[index].sections[sectionIndex].options = maintainDxOptionData(
      dxCodes,
      dataList[index].sections[sectionIndex].options,
    )
  }

  let tempSecs = [...selectedSections]
  let indexSelectedSec =
    tempSecs?.findIndex((item) =>
      item?.sections?.find((it) => it?.type === 'diagnosisPlan' || it?.type === 'diagnosis'),
    ) ?? -1
  if (indexSelectedSec > -1) {
    let newValue = chartArrayGenerater(dataList[index])?.join('\n')
    if (dataList[index]?.values) {
      newValue = dataList[index]?.values
    }
    tempSecs[indexSelectedSec] = {
      ...tempSecs[indexSelectedSec],
      isTemplate: isTemplate,
      values: [newValue],
    }
  }

  return { template: { ...tempData, data: dataList }, section: [...tempSecs] }
}

const updateSelectedSectionMips = (selectedSections, newMIPS, selectedTemplate) => {
  let newSelectedSections = [...selectedSections]
  let mipsIndex = newSelectedSections?.findIndex((item) => item?.title === 'MIPS')
  if (mipsIndex > -1 && newSelectedSections[mipsIndex]) {
    newSelectedSections[mipsIndex].sections = newMIPS

    // can't wait for this to break
    // when we update the template values, make sure it stays in sync with selected section values
    let templateMipsIndex = selectedTemplate?.data?.findIndex((item) => item?.title === 'MIPS')
    if (templateMipsIndex > -1 && selectedTemplate?.data[templateMipsIndex]) {
      newSelectedSections[mipsIndex].values = selectedTemplate?.data[templateMipsIndex].values
    }
  }

  return newSelectedSections
}

const setMIPSdata = (template, mips) => {
  let newData = template?.data
  let mipsIndex = newData?.findIndex((item) => item?.title === 'MIPS')
  if (mipsIndex >= 0 && newData[mipsIndex]) {
    if (newData[mipsIndex]?.sections) {
      // I don't think we can ensure attestations,
      // since answer picking and mips recalculations both go through here
      newData[mipsIndex].sections = mips
    }

    // if there is a mips section and any of the incoming mips are attested to
    if (mips?.find((item) => item?.value)) {
      newData[mipsIndex].skip = false

      // TODO - this logic all smells, rewrite
      // check git history to undo, but for now, always recalculate the value
      newData[mipsIndex].values = [
        mips
          ?.filter((item) => item?.value)
          ?.map((item) => `${item?.label}: ${item?.value}`)
          ?.join(', \n'),
      ]
    } else {
      newData[mipsIndex].values = []
    }
  }

  return { ...template, data: newData }
}

export const checkItsHaveOnlyTextArea = (section) => {
  let thisSectionList = [...section?.sections]

  let textareaSection = thisSectionList?.filter((sec) => {
    let textareaOptions = sec?.options?.filter((item) => item?.inputType === 'textarea')
    return textareaOptions?.length === sec?.options?.length && textareaOptions?.length > 0
  })

  return textareaSection?.length === thisSectionList?.length
}

const extractTextAreaValue = (state, text) => {
  let selectedTemplate = state?.selectedTemplate
  let templateData = selectedTemplate?.data
  let sectionIndex = -1
  let selectedSection = templateData?.find((sec, i) => {
    if (sec?.title === state?.activeSection?.title) {
      sectionIndex = i
      return true
    } else {
      return false
    }
  })

  let textArray = text?.split(' ')

  for (let i = 0; i < selectedSection?.sections?.length; i++) {
    for (let j = 0; j < selectedSection?.sections[i]?.options?.length; j++) {
      if (selectedSection?.sections[i]?.options[j]?.inputType === 'textarea') {
        selectedSection.sections[i].options[j].value = textArray[j]
          ?.replace(selectedSection?.sections[i]?.options[j]?.label, '')
          ?.replace(';', '')
      }
    }
  }

  templateData[sectionIndex] = selectedSection

  return { ...selectedTemplate, data: templateData }
}

const setDisableField = (state, text) => {
  let selectedTemplate = state?.selectedTemplate
  let templateData = selectedTemplate?.data
  let sectionIndex = -1
  let selectedSection = templateData?.find((sec, i) => {
    if (sec?.title === state?.activeSection?.title) {
      sectionIndex = i
      return true
    } else {
      return false
    }
  })

  let disableSec = selectedSection?.sections?.map((sec, i) => {
    if (text?.includes(sec?.label) && sec?.label != '') {
      return { ...sec, disable: true }
    } else if (sec?.label === '') {
      let newSec = sec
      for (let i = 0; i < sec?.options?.length; i++) {
        const opt = sec?.options[i]

        // preventing permanent disabling, by forcing text to exist before
        // we disable right side
        if (text && text?.includes(opt?.label)) {
          newSec = { ...sec, disable: true }
          break
        } else {
          newSec = { ...sec, disable: false }
        }
      }
      return newSec
    } else {
      return { ...sec, disable: false }
    }
  })

  selectedSection.sections = disableSec

  templateData[sectionIndex] = selectedSection

  return { ...selectedTemplate, data: templateData }
}

export const {
  setSelectedTemplate,
  setProcedureCode,
  setDiagnosisCode,
  resetProgressNote,
  onFirstNoteLoad,
  setNoteValidation,
  updateChartErrors,
  setSelectedSections,
  setActiveSection,
  setNoteSectionValue,
  initiateDiagnosisPlan,
  changeMIPSdata,
  onTemplateValueSet,
  onSnippetAdd,
  setIsSaveDraft,
  changeCursor,
  onDotPhraseSnippetAdd,
  setCheckCareCoordination,
} = progressNoteSlice.actions

export default progressNoteSlice.reducer
