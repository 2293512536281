import moment from 'moment'
const formatter = new Intl.NumberFormat('en-US')
const formatterRevenue = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const percardInfo = [
  {
    date: 'January 2022',
    rev: '$ 31.420,75',
    textdata: 'Highest monthly practice revenue',
  },
  {
    date: '15 June 2022',
    rev: '72 visits',
    textdata: 'Most encounter per day',
  },
  {
    date: 'March 2022',
    rev: 2310,
    textdata: 'Top month (RVU)',
  },
]

export const chartOptions = {
  elements: {
    line: {
      tension: 0.4,
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function () {
          return ''
        },
        label: function (context) {
          let label = ''
          if (context.parsed.x !== null) {
            label = formatter.format(parseFloat(context.parsed.y).toFixed(2))
          }
          return label
        },
      },
    },
  },
  scales: {
    x: {
      display: true,
      stacked: true,
    },
    y: {
      display: true,
      stacked: true,
    },
  },
}
export const chartOptionsRevenue = {
  elements: {
    line: {
      tension: 0.4,
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function () {
          return ''
        },
        label: function (context) {
          let label = ''
          if (context.parsed.x !== null) {
            label = formatterRevenue.format(parseFloat(context.parsed.y).toFixed(2))
          }
          return label
        },
      },
    },
  },
  scales: {
    x: {
      display: true,
      stacked: true,
    },
    y: {
      display: true,
      stacked: true,
    },
  },
}
export const chartOptions1 = {
  elements: {
    line: {
      tension: 0.4,
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        title: function () {
          return ''
        },
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: true,
      stacked: true,
    },
    y: {
      display: true,
      stacked: true,
    },
  },
}

export const staticChartOptions = {
  elements: {
    line: {
      tension: 0.4,
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      maxHeight: '100',
      labels: {
        boxWidth: 15,
        boxHeight: 12,
        textAlign: 'left',
        color: '#ffffff',
        font: {
          size: 10,
          weight: 400,
          lineHeight: '8px',
          family: 'Inter',
        },
      },
    },
  },
  scales: {
    x: {
      display: true,
      stacked: true,
    },
    y: {
      display: true,
      stacked: true,
    },
  },
}

//calender-data
export const currentMonth = moment().format('YYYY-MM')
export const currentYear = moment().format('YYYY')
export const today = moment().format('YYYY-MM-DD')
export const calendarDropDownData = [
  {
    id: currentMonth,
    name: `Current Month`,
  },
  {
    id: 'This week',
    name: `Current Week`,
  },
  {
    id: currentYear,
    name: `Current Year`,
  },
  {
    id: 'Previous month',
    name: `Last Month`,
  },
  {
    id: 'Previous week',
    name: `Last Week`,
  },
  {
    id: 'previousyear',
    name: `Last Year`,
  },

  {
    id: 'custom',
    name: `Custom`,
  },
  // {
  //   id: 'Select period...',
  //   name: `Select period...`,
  // },
]

export const facilityStatistics = {
  labels: ['1-7', '8-14', '15-21', '22-28', '29-31'],
  datasets: [
    {
      label: 'Previously seen patients',
      backgroundColor: 'rgba(148, 109, 240, 0.7)',
      data: [27, 39, 25, 55, 4],
      barPercentage: 0.5,
      borderRadius: 10,
    },
    {
      label: 'New patients',
      backgroundColor: '#9BE8E2',
      data: [15, 20, 18, 20, 0],
      barPercentage: 0.5,
      borderRadius: 5,
    },
  ],
}

export const facilityStaticsCard = [
  {
    count: 90,
    data: 'Patient seen',
  },
  { count: 40, data: 'New Patients' },
  { count: 3, data: 'New intake' },
  { count: 10, data: 'discharge' },
]

export const graphBarColors = [
  '#78ACAC',
  '#6D5D94',
  '#875DE6',
  '#5414DD',
  '#C62F2F',
  '#065535',
  '#40e0d0',
  '#ffa500',
  '#ff7373',
  '#133337',
  '#FF00FF',
  '#00FF00',
  '#FFFF00',
  '#ab0068',
  '#fa7445',
  '#626a4c',
  '#7b5c62',
  '#e08dff',
  '#0909FA',
  '#385750',
  '#63E2C4',
  '#26822F',
  '#67B76F',
  '#0F3F30',
  '#01EFA7',
  '#3372EF',
  '#032771',
  '#5E0B78',
  '#4657C1',
  '#C1B146',
  '#8DB5B9',
  '#DB8599',
  '#7AE6D1',
  '#E67E7A',
  '#F67873',
  '#759300',
  '#609E9A',
]
