import { createSlice } from '@reduxjs/toolkit'

import { getFacilities, getPracticeOverview, getInitialProcedure } from './dashbordThunk'

const initialState = {
  censusFacility: {},
  facilitiesList: [],
  allFacilitiesList: [],
  isloading: false,
  isError: false,
  errorMsg: null,
  activeFacility: null,
  patients: {
    totalPatientCount: 0,
    prevTotalCount: 0,
    comparisonPercentage: 0,
  },
  procedure_modifiers: [],
  encounters: {
    totalencountersCount: 0,
    prevTotalCount: 0,
    comparisonPercentage: 0,
  },
  revenue: {
    totalrevenueCount: 0,
    prevTotalCount: 0,
    comparisonPercentage: 0,
  },
  rvu: {
    totalrvuCount: 0,
    prevTotalCount: 0,
    comparisonPercentage: 0,
  },
  initialProcedure: [],
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setFacilitiesList: (state, action) => {
      state.facilitiesList = action.payload
    },
    setActiveFacility: (state, action) => {
      state.activeFacility = action.payload
    },
    setProcedureModifiers: (state, action) => {
      state.procedure_modifiers = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPracticeOverview.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getPracticeOverview.fulfilled, (state, action) => {
        return { ...state, ...action?.payload, isloading: false }
      })
      .addCase(getPracticeOverview.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(getFacilities.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getFacilities.fulfilled, (state, action) => {
        state.isloading = false
        state.allFacilitiesList = action.payload?.allFacilitiesList
          ? action.payload?.allFacilitiesList
          : []
      })
      .addCase(getFacilities.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(getInitialProcedure.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getInitialProcedure.fulfilled, (state, action) => {
        state.isloading = false
        state.initialProcedure = action.payload?.initialProcedure
          ? action.payload?.initialProcedure
          : []
      })
      .addCase(getInitialProcedure.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
  },
})

export const { setProcedureModifiers } = dashboardSlice.actions
export const selectDashbord = (state) => state.dashboard
export default dashboardSlice.reducer
