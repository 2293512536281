import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getDataPractice, resetState, getSwitcherTabData } from './PracticIqThunk'

const initialState = {
  encounters: {
    encRes: [],
    dateWiseEnc: {},
    monthwiseEnc: [],
    tableData: [],
    lastYearEncRes: {},
    lastYearmonthwiseEncRes: [],
    groupedTable: [],
    groupedResults: [],
    facilityList: [],
    prevYearTableData: [],
    bestYearMonth: [],
    beforeLastYearEncRes: {},
  },
  beforeYearData: {},
  currentDateRange: '',
  currentDateValue: '',
  cardsData: {},
  lastYearCardsData: {},
  facilityList: [],
  userRoles: [],
  cardsYearMonth: [],
  status: 'fullfilled',
  prevMonth: [],
  priorMonth: [],
  prvMonthCount: 0,
  priorMonthCount: 0,
  prevMonthDates: {},
  priorMonthDates: {},
  prvWeekData: [],
  priorWeekData: [],
  prvWeekCount: 0,
  priorWeekCount: 0,
  prvWeekDates: {},
  priorWeekDates: {},
  isloading: false,
  manualRefresh: false,
  activeButton: 'Provider',
}

const practiceIqSlice = createSlice({
  name: 'encounters',
  initialState,
  reducers: {
    setFilterdCalData: (state, action) => {
      state.encounters.tableData = action.payload.data
        .map((x) => x)
        .sort((a, b) => moment(a.month) - moment(b.month))
      state.encounters.groupedTable = action.payload.data
        .map((x) => x)
        .sort((a, b) => moment(a.month) - moment(b.month))
      state.encounters.groupedResults = action.payload.filtResults
      //
    },
    setGroupedData: (state, action) => {
      state.encounters.groupedTable = action.payload
    },
    setFacilityList: (state, action) => {
      state.encounters.facilityList = action.payload
    },
    setLastYearFilteredCalData: (state, action) => {
      state.encounters.prevYearTableData = action.payload
    },
    setActiveButton: (state, action) => {
      state.activeButton = action.payload
    },
    setManualRefresh: (state, action) => {
      state.manualRefresh = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetState.fulfilled, (state, action) => {
      state.currentDateRange = 'Current Month'
      state.currentDateValue = ''
    })
    builder.addCase(getDataPractice.pending, (state, action) => {
      state.isloading = true
      state.status = 'pending'
    })
    builder.addCase(getDataPractice.fulfilled, (state, action) => {
      state.manualRefresh = false
      state.isloading = false
      state.status = 'fulfilled'
      state.currentDateRange = action.payload.name
      state.currentDateValue = ''
      if (action.payload.name === 'custom') state.currentDateValue = action.payload.currentDateValue
      state.encounters.encRes = action.payload.encDataByYear
      // console.log(action, action.payload.dates, action.payload.dates1)
      state.encounters.dateWiseEnc = { ...action.payload.dates, ...action.payload.dates1 }
      state.encounters.monthwiseEnc = action.payload.monthWiseData
      state.encounters.tableData = action.payload.daysInMonthData
      state.encounters.lastYearEncRes = action.payload.prevYearData
      state.encounters.lastYearmonthwiseEncRes = action.payload.lastYearMonthWiseData
      state.encounters.groupedTable = action.payload.daysInMonthData
      state.encounters.groupedResults = action.payload.monthRes
      state.encounters.PrevYearCompleteEncRes = action.payload.LastYearEncDataByYear
      state.encounters.prevYearTableData = action.payload.lastYearDaysInMonthData
      state.encounters.bestYearMonth = action.payload.bestYearAndMonthData
      state.facilityList = action.payload.faciltyList
      state.userRoles = action.payload.userRoles
      state.cardsData = { ...action.payload.cardsData, ...action.payload.cardsData1 }
      state.lastYearCardsData = action.payload.lastYearCardsData
      state.encounters.beforeLastYearEncRes = action.payload.beforePrevYearData
      state.beforeYearData = action.payload.beforelastYearCardsData
      const prv = []
      const prior = []
      const prvWeek = []
      const priorWeek = []
      let prv_week_total = 0
      let prior_week_total = 0
      let prv_count = 0
      let prior_count = 0
      let prv_month_date = {}
      let prior_month_date = {}
      let prv_week_dates = {}
      let prior_week_dates = {}

      // if (moment().subtract(1, 'week').isSame(x?._id?.service_date_from, 'week')) {
      //   prv_week.push(x)
      // }
      // if (moment().subtract(2, 'week').isSame(x?._id?.service_date_from, 'week')) {
      //   prior_week.push(x)
      // }
      if (action.payload?.monthDataCurrYear || action.payload?.monthDataPrevYear) {
        const prv_month = moment().subtract(1, 'months').format('YYYY-MM')
        const prior_month = moment().subtract(2, 'months').format('YYYY-MM')

        for (let prop in action.payload?.monthDataPrevYear) {
          action.payload?.monthDataPrevYear[prop].forEach((itm) => {
            if (prv_month === moment(itm?._id.service_date_from).format('YYYY-MM')) {
              prv.push(itm)
              prv_count += itm?.total_count
            }
            if (prior_month === moment(itm?._id.service_date_from).format('YYYY-MM')) {
              prior.push(itm)
              prior_count += itm?.total_count
            }
          })
        }
        for (let prop in action.payload?.monthDataCurrYear) {
          action.payload?.monthDataCurrYear[prop].forEach((itm) => {
            if (prv_month === moment(itm?._id.service_date_from).format('YYYY-MM')) {
              prv.push(itm)
              prv_count += itm?.total_count
            }
            if (prior_month === moment(itm?._id.service_date_from).format('YYYY-MM')) {
              prior.push(itm)
              prior_count += itm?.total_count
            }
          })
        }
        /////

        for (let prop in action.payload?.weekDataPrevYear) {
          action.payload?.weekDataPrevYear[prop].forEach((itm) => {
            if (moment().subtract(1, 'week').isSame(itm?._id?.service_date_from, 'week')) {
              prvWeek.push(itm)
              prv_week_total += itm?.total_count
            }
            if (moment().subtract(2, 'week').isSame(itm?._id?.service_date_from, 'week')) {
              priorWeek.push(itm)
              prior_week_total += itm?.total_count
            }
          })
        }
        for (let prop in action.payload?.weekDataCurrYear) {
          action.payload?.weekDataCurrYear[prop].forEach((itm) => {
            if (moment().subtract(1, 'week').isSame(itm?._id?.service_date_from, 'week')) {
              prvWeek.push(itm)
              prv_week_total += itm?.total_count
            }
            if (moment().subtract(2, 'week').isSame(itm?._id?.service_date_from, 'week')) {
              priorWeek.push(itm)
              prior_week_total += itm?.total_count
            }
          })
        }
      }

      // action.payload?.weekDataCurrYear?.prv_week.forEach((itm) => {
      //   totalll += itm?.total_count
      // })

      prv.forEach((x, indx) => {
        if (indx === 0) {
          prv_month_date[x?._id.service_date_from] = x?.total_count
        } else {
          prv_month_date[x?._id.service_date_from] = prv_month_date.hasOwnProperty(
            x?._id.service_date_from,
          )
            ? prv_month_date[x?._id.service_date_from] + x?.total_count
            : x?.total_count
        }
      })
      prior.forEach((x, indx) => {
        if (indx === 0) {
          prior_month_date[x?._id.service_date_from] = x?.total_count
        } else {
          prior_month_date[x?._id.service_date_from] = prior_month_date.hasOwnProperty(
            x?._id.service_date_from,
          )
            ? prior_month_date[x?._id.service_date_from] + x?.total_count
            : x?.total_count
        }
      })

      prvWeek.forEach((x, indx) => {
        if (indx === 0) {
          prv_week_dates[x?._id.service_date_from] = x?.total_count
        } else {
          prv_week_dates[x?._id.service_date_from] = prv_week_dates.hasOwnProperty(
            x?._id.service_date_from,
          )
            ? prv_week_dates[x?._id.service_date_from] + x?.total_count
            : x?.total_count
        }
      })

      priorWeek.forEach((x, indx) => {
        if (indx === 0) {
          prior_week_dates[x?._id.service_date_from] = x?.total_count
        } else {
          prior_week_dates[x?._id.service_date_from] = prior_week_dates.hasOwnProperty(
            x?._id.service_date_from,
          )
            ? prior_week_dates[x?._id.service_date_from] + x?.total_count
            : x?.total_count
        }
      })
      //console.log('PRV_MNTH', prv_month_date, 'PRIOR', prior_month_date)

      state.prevMonth = prv
      state.priorMonth = prior
      state.prvMonthCount = prv_count
      state.priorMonthCount = prior_count
      state.prevMonthDates = prv_month_date
      state.priorMonthDates = prior_month_date

      //
      state.prvWeekData = prvWeek
      state.priorWeekData = priorWeek
      state.prvWeekCount = prv_week_total
      state.priorWeekCount = prior_week_total
      state.prvWeekDates = prv_week_dates
      state.priorWeekDates = prior_week_dates
    })
    builder.addCase(getDataPractice.rejected, (state, action) => {
      state.isloading = false
      state.status = 'rejected'
    })

    builder.addCase(getSwitcherTabData.pending, (state, action) => {
      state.status = 'pending'
    })
    builder.addCase(getSwitcherTabData.fulfilled, (state, action) => {
      state.status = 'fulfilled'
      state.cardsYearMonth = action.payload.bestYearAndMonthDataPractice
    })
    builder.addCase(getSwitcherTabData.rejected, (state, action) => {
      state.status = 'rejected'
    })
  },
})

export const {
  setFilterdCalData,
  setGroupedData,
  setFacilityList,
  setLastYearFilteredCalData,
  setActiveButton,
  setManualRefresh,
} = practiceIqSlice.actions
export default practiceIqSlice.reducer
