import React from 'react'
import { useSelector } from 'react-redux'
import './Button.scss'
import { CButton } from '@coreui/react-pro'
import PropTypes from 'prop-types'

const Button = ({
  children,
  color = 'primary',
  compressed,
  disabled,
  fullWidth,
  halfWidth,
  onClick,
  variant,
  ...rest
}) => {
  const theme = useSelector((state) => state.layout.theme)

  return (
    <CButton
      className={`claim-button ${color} ${variant} ${fullWidth ? 'full-width' : 'padding'} ${
        compressed && 'compressed'
      } ${halfWidth && 'halfWidth'}`}
      style={{ border: theme === 'light' && color === 'secondary' ? '1px solid #232529' : 'none' }}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </CButton>
  )
}

Button.propTypes = {
  children: PropTypes.string || PropTypes.node,
  color: PropTypes.string,
  compressed: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.string,
}

export default Button
