import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  theme: window.localStorage.getItem('theme-setting') || 'dark',
}

const layoutSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
})

export default layoutSlice.reducer
