import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCollector } from 'src/apiHelper/collectorApi'
import moment from 'moment'
import { getFacilityListData, getFromSettingsServer } from 'src/apiHelper/settingsApi'
import { getDates } from './practiceUtility'
import { COLLECTOR_URL } from '../../apiHelper/apiRoute/collector'
import { SETTINGS_URL } from '../../apiHelper/apiRoute/settings'

let startDayOfYear = moment().startOf('year').format('YYYY-MM-DD')
let endDayOfYear = moment().endOf('year').format('YYYY-MM-DD')

let startOfLastYear = moment().startOf('year').subtract(1, 'years').format('YYYY-MM-DD')
let endOfLastYear = moment().endOf('year').subtract(1, 'years').format('YYYY-MM-DD')

let startOfBeforeLastYear = moment().startOf('year').subtract(2, 'years').format('YYYY-MM-DD')
let endOfBeforeLastYear = moment().endOf('year').subtract(2, 'years').format('YYYY-MM-DD')

let currentYearOutSide, prevYearOutside

const encUrl = COLLECTOR_URL.GET_ENCOUNTER_BY_YEAR.replace(
  '{startDate}',
  `${startDayOfYear}`,
).replace('{endDate}', `${endDayOfYear}`)
const lastYearEncUrl = COLLECTOR_URL.GET_ENCOUNTER_BY_YEAR.replace(
  '{startDate}',
  `${startOfLastYear}`,
).replace('{endDate}', `${endOfLastYear}`)
const lastYearRevUrl = COLLECTOR_URL.GET_REVENUE_BY_YEAR.replace(
  '{startDate}',
  `${startOfLastYear}`,
).replace('{endDate}', `${endOfLastYear}`)

const revenueUrl = COLLECTOR_URL.GET_REVENUE_BY_YEAR.replace(
  '{startDate}',
  `${startDayOfYear}`,
).replace('{endDate}', `${endDayOfYear}`)
const rvuUrl = COLLECTOR_URL.GET_RVU_BY_YEAR.replace('{startDate}', `${startDayOfYear}`).replace(
  '{endDate}',
  `${endDayOfYear}`,
)
const lastYearRvuUrl = COLLECTOR_URL.GET_RVU_BY_YEAR.replace(
  '{startDate}',
  `${startOfLastYear}`,
).replace('{endDate}', `${endOfLastYear}`)
const bestYearEncUrl = COLLECTOR_URL.GET_BEST_YEAR_ENCOUNTER.replace('{type}', `${'practice'}`)
const lastBeforeYearEncUrl = COLLECTOR_URL.GET_ENCOUNTER_BY_YEAR.replace(
  '{startDate}',
  `${startOfBeforeLastYear}`,
).replace('{endDate}', `${endOfBeforeLastYear}`)
const lastBeforeYearRevUrl = COLLECTOR_URL.GET_REVENUE_BY_YEAR.replace(
  '{startDate}',
  `${startOfBeforeLastYear}`,
).replace('{endDate}', `${endOfBeforeLastYear}`)

const rvuBeforeUrl = COLLECTOR_URL.GET_RVU_BY_YEAR.replace(
  '{startDate}',
  `${startOfBeforeLastYear}`,
).replace('{endDate}', `${endOfBeforeLastYear}`)

const cencUrl = COLLECTOR_URL.GET_ENCOUNTER_BY_YEAR
//   .replace(
//   '{startDate}',
//   `${startDayOfYear}`,
// ).replace('{endDate}', `${endDayOfYear}`)
const clastYearEncUrl = COLLECTOR_URL.GET_ENCOUNTER_BY_YEAR
//   .replace(
//   '{startDate}',
//   `${startOfLastYear}`,
// ).replace('{endDate}', `${endOfLastYear}`)
const clastYearRevUrl = COLLECTOR_URL.GET_REVENUE_BY_YEAR
//   .replace(
//   '{startDate}',
//   `${startOfLastYear}`,
// ).replace('{endDate}', `${endOfLastYear}`)

const crevenueUrl = COLLECTOR_URL.GET_REVENUE_BY_YEAR
//   .replace(
//   '{startDate}',
//   `${startDayOfYear}`,
// ).replace('{endDate}', `${endDayOfYear}`)
const crvuUrl = COLLECTOR_URL.GET_RVU_BY_YEAR
//   .replace(
//   '{startDate}',
//   `${startDayOfYear}`,
// ).replace('{endDate}', `${endDayOfYear}`)
const clastYearRvuUrl = COLLECTOR_URL.GET_RVU_BY_YEAR
//   .replace(
//   '{startDate}',
//   `${startOfLastYear}`,
// ).replace('{endDate}', `${endOfLastYear}`)
// const cbestYearEncUrl = COLLECTOR_URL.GET_BEST_YEAR_ENCOUNTER.replace(
//   '{type}',
//   `${'practice'}`,
// )
const clastBeforeYearEncUrl = COLLECTOR_URL.GET_ENCOUNTER_BY_YEAR
//   .replace(
//   '{startDate}',
//   `${startOfBeforeLastYear}`,
// ).replace('{endDate}', `${endOfBeforeLastYear}`)
const clastBeforeYearRevUrl = COLLECTOR_URL.GET_REVENUE_BY_YEAR
//   .replace(
//   '{startDate}',
//   `${startOfBeforeLastYear}`,
// ).replace('{endDate}', `${endOfBeforeLastYear}`)

const crvuBeforeUrl = COLLECTOR_URL.GET_RVU_BY_YEAR
//   .replace(
//   '{startDate}',
//   `${startOfBeforeLastYear}`,
// ).replace('{endDate}', `${endOfBeforeLastYear}`)

const bestYearRvuUrl = COLLECTOR_URL.GET_BEST_YEAR_RVU.replace('{type}', `${'practice'}`)
const bestYearRevenueUrl = COLLECTOR_URL.GET_BEST_YEAR_REVENUE.replace('{type}', `${'practice'}`)
const compareTwoDates = (val1) => {
  return moment(val1.serv_date).format(val1.dateFormat) ===
    moment(currentYearOutSide).format(val1.dateFormat)
    ? val1.count + val1.prevCount
    : val1.prevCount
}

const lastYearData = (data) => {
  const prev_month = []
  const befor_prev_mnth = []
  const prv_week = []
  const prior_week = []
  let cardsData1 = {
    prevMonthLength: {},
    preWeekLength: {},
  }
  let prevYearData = {
    lastYear: 0,
    lastYearCurrentMonth: 0,
    lastYearLastMonth: 0,
    lastYearCurrentWeek: 0,
    prevYearCurrentMonth: {
      lastYear: 0,
    },
  }
  let dates1 = {
    prevMonth: 0,
    lastWeek: 0,
  }
  let lastYearMonthWiseData = {}
  const lastYearDaysMonthData = {}
  const lastYearDaysInMonthData = []
  let lastYearCardsData = {
    lastYearCurrentMonthLength: {},
    lastYearPrevMonthLength: {},
    lastYearCurrentWeekLength: {},
    lastYearPreWeekLength: {},
    lastYearLength: {},
  }
  data.length > 0 &&
    data?.map((x, indx) => {
      dates1.lastWeek = moment(currentYearOutSide)
        .subtract(1, 'weeks')
        .isSame(x._id.service_date_from, 'week')
        ? x.total_count + dates1.lastWeek
        : dates1.lastWeek
      dates1.prevMonth = moment(currentYearOutSide)
        .subtract(1, 'months')
        .isSame(x._id.service_date_from, 'month')
        ? x.total_count + dates1.prevMonth
        : dates1.prevMonth

      if (
        moment(currentYearOutSide).subtract(1, 'months').isSame(x._id.service_date_from, 'month')
      ) {
        cardsData1.prevMonthLength[x._id.service_date_from] = x.total_count
      }
      if (moment(currentYearOutSide).subtract(1, 'weeks').isSame(x._id.service_date_from, 'week')) {
        cardsData1.preWeekLength[x._id.service_date_from] = x.total_count
      }
      if (
        moment(currentYearOutSide).subtract(2, 'months').format('YYYY-MM') ===
        moment(x._id.service_date_from).format('YYYY-MM')
      ) {
        befor_prev_mnth.push(x)
      }
      if (
        moment(currentYearOutSide).subtract(1, 'months').format('YYYY-MM') ===
        moment(x._id.service_date_from).format('YYYY-MM')
      ) {
        prev_month.push(x)
      }
      //dates - comes under current month
      if (
        moment(currentYearOutSide).subtract(1, 'week').isSame(x?._id?.service_date_from, 'week')
      ) {
        prv_week.push(x)
      }
      if (
        moment(currentYearOutSide).subtract(2, 'week').isSame(x?._id?.service_date_from, 'week')
      ) {
        prior_week.push(x)
      }
      //
      if (
        moment(x._id.service_date_from).isSame(
          moment(currentYearOutSide).subtract(1, 'years'),
          'month',
        )
      ) {
        lastYearCardsData.lastYearCurrentMonthLength[x._id.service_date_from] = x.total_count
        lastYearDaysMonthData[moment(x._id.service_date_from).format('MM-DD-YYYY')] =
          typeof lastYearDaysMonthData[moment(x._id.service_date_from).format('MM-DD-YYYY')] !==
          'undefined'
            ? lastYearDaysMonthData[moment(x._id.service_date_from).format('MM-DD-YYYY')] +
              x.total_count
            : x.total_count
      }

      lastYearCardsData.lastYearLength[x._id.service_date_from] = x.total_count

      if (
        moment(currentYearOutSide)
          .subtract(1, 'years')
          .subtract(1, 'months')
          .isSame(x._id.service_date_from, 'month')
      ) {
        lastYearCardsData.lastYearPrevMonthLength[x._id.service_date_from] = x.total_count
      }

      if (
        moment(currentYearOutSide)
          .subtract(1, 'years')
          .subtract(1, 'week')
          .isSame(x._id.service_date_from, 'month')
      ) {
        lastYearCardsData.lastYearCurrentWeekLength[x._id.service_date_from] = x.total_count
      }

      if (moment(currentYearOutSide).subtract(1, 'weeks').isSame(x._id.service_date_from, 'week')) {
        lastYearCardsData.lastYearPreWeekLength[x._id.service_date_from] = x.total_count
      }
      //data - monthwise
      lastYearMonthWiseData[moment(x._id.service_date_from).format('YYYY-MMMM')] =
        typeof lastYearMonthWiseData[moment(x._id.service_date_from).format('YYYY-MMMM')] !==
        'undefined'
          ? lastYearMonthWiseData[moment(x._id.service_date_from).format('YYYY-MMMM')] +
            x.total_count
          : x.total_count

      if (indx === 0) {
        prevYearData.lastYear = moment(currentYearOutSide)
          .subtract(1, 'year')
          .isSame(x._id.service_date_from, 'year')
          ? x.total_count
          : prevYearData.prevYearCurrentMonth.lastYear
        prevYearData.lastYearCurrentMonth = moment(currentYearOutSide)
          .subtract(1, 'years')
          .isSame(x._id.service_date_from, 'month')
          ? x.total_count
          : prevYearData.lastYearCurrentMonth
        prevYearData.lastYearLastMonth = moment(currentYearOutSide)
          .subtract(1, 'years')
          .subtract(1, 'months')
          .isSame(x._id.service_date_from, 'month')
          ? x.total_count
          : prevYearData.lastYearCurrentMonth
        prevYearData.lastYearCurrentWeek = moment(currentYearOutSide)
          .subtract(1, 'years')
          .subtract(1, 'week')
          .isSame(x._id.service_date_from, 'week')
          ? x.total_count
          : prevYearData.lastYearCurrentWeek
      } else {
        prevYearData.lastYear = moment(currentYearOutSide)
          .subtract(1, 'year')
          .isSame(x._id.service_date_from, 'year')
          ? x.total_count + prevYearData.lastYear
          : prevYearData.lastYear
        prevYearData.lastYearCurrentMonth = moment(currentYearOutSide)
          .subtract(1, 'years')
          .isSame(x._id.service_date_from, 'month')
          ? x.total_count + prevYearData.lastYearCurrentMonth
          : prevYearData.lastYearCurrentMonth
        prevYearData.lastYearLastMonth = moment(currentYearOutSide)
          .subtract(1, 'years')
          .subtract(1, 'months')
          .isSame(x._id.service_date_from, 'month')
          ? x.total_count + prevYearData.lastYearLastMonth
          : prevYearData.lastYearLastMonth
        prevYearData.lastYearCurrentWeek = moment(currentYearOutSide)
          .subtract(1, 'years')
          .subtract(1, 'week')
          .isSame(x._id.service_date_from, 'week')
          ? x.total_count + prevYearData.lastYearCurrentWeek
          : prevYearData.lastYearCurrentWeek
      }
    })
  let total1 = 0
  // console.log('befor_prev_mnth', befor_prev_mnth)
  befor_prev_mnth.forEach((x) => {
    total1 += x?.total_count
  })

  // console.log('prev-total', total1)

  const newData = []
  if (Object.keys(lastYearMonthWiseData).length === 0) {
    newData.push({
      month: moment(currentYearOutSide).format('MM-DD-YYYY'),
      encounters: 0,
    })
  } else {
    for (let prop in lastYearMonthWiseData) {
      newData.push({
        monthinitial: moment(prop).format('DD-MM-YYYY'),
        month: prop,
        encounters: lastYearMonthWiseData[prop],
      })
    }
  }
  if (Object.keys(lastYearDaysMonthData).length === 0) {
    lastYearDaysInMonthData.push({
      month: moment(currentYearOutSide).format('MM-DD-YYYY'),
      encounters: 0,
    })
  } else {
    for (let prop in lastYearDaysMonthData) {
      lastYearDaysInMonthData.push({
        monthinitial: moment(prop).format('DD-MM-YYYY'),
        month: prop,
        encounters: lastYearDaysMonthData[prop],
      })
    }
  }
  return {
    dates1,
    cardsData1,
    prevYearData,
    LastYearEncDataByYear: data,
    lastYearMonthWiseData: newData,
    lastYearDaysInMonthData,
    lastYearCardsData,
    monthDataPrevYear: {
      prev_month,
      befor_prev_mnth,
    },
    weekDataPrevYear: {
      prv_week,
      prev_month,
    },
  }
}
const filterData = (data) => {
  const isDate = moment().subtract(1, 'months').format('YYYY-MM')
  const prev_month = []
  const prior_month = []
  const prv_week = []
  const prior_week = []

  let count = 0
  let dates = {
    week: 0,
    year: 0,
    currentMonth: 0,
    currentMonthLength: 0,
    prevMonth: 0,
    today: 0,
    lastWeek: 0,
    yesterday: 0,
  }
  let monthWiseData = {}
  const daysMonthData = {}
  const daysInMonthData = []
  const monthRes = []
  let cardsData = {
    currentMonthLength: {},
    prevMonthLength: {},
    currentWeekLength: {},
    currentYearLength: {},
    preWeekLength: {},
  }
  data?.map((x, indx) => {
    const dataInfo = {
      serv_date: x._id.service_date_from,
      count: x.total_count,
    }
    if (isDate === moment(x?._id?.service_date_from).format('YYYY-MM')) {
      prev_month.push(x)
    }
    if (
      moment().subtract(2, 'months').format('YYYY-MM') ===
      moment(x?._id?.service_date_from).format('YYYY-MM')
    ) {
      prior_month.push(x)
    }
    if (moment().subtract(1, 'week').isSame(x?._id?.service_date_from, 'week')) {
      prv_week.push(x)
    }
    if (moment().subtract(2, 'week').isSame(x?._id?.service_date_from, 'week')) {
      prior_week.push(x)
    }
    if (moment(x._id.service_date_from).isSame(currentYearOutSide, 'month')) {
      cardsData.currentMonthLength[x._id.service_date_from] = x.total_count
      count += x.total_count
      daysMonthData[moment(x._id.service_date_from).format('MM-DD-YYYY')] =
        typeof daysMonthData[moment(x._id.service_date_from).format('MM-DD-YYYY')] !== 'undefined'
          ? daysMonthData[moment(x._id.service_date_from).format('MM-DD-YYYY')] + x.total_count
          : x.total_count
      monthRes.push(x)
    }

    cardsData.currentYearLength[x._id.service_date_from] = x.total_count

    if (moment(x._id.service_date_from).isSame(currentYearOutSide, 'week')) {
      cardsData.currentWeekLength[x._id.service_date_from] = x.total_count
    }

    if (moment(currentYearOutSide).subtract(1, 'months').isSame(x._id.service_date_from, 'month')) {
      cardsData.prevMonthLength[x._id.service_date_from] = x.total_count
    }
    if (moment(currentYearOutSide).subtract(1, 'weeks').isSame(x._id.service_date_from, 'week')) {
      cardsData.preWeekLength[x._id.service_date_from] = x.total_count
    }
    //data - monthwise
    monthWiseData[moment(x._id.service_date_from).format('YYYY-MMMM')] =
      typeof monthWiseData[moment(x._id.service_date_from).format('YYYY-MMMM')] !== 'undefined'
        ? monthWiseData[moment(x._id.service_date_from).format('YYYY-MMMM')] + x.total_count
        : x.total_count

    if (indx === 0) {
      dates.currentMonth = compareTwoDates({ ...dataInfo, dateFormat: 'YYYY-MM', prevCount: 0 })

      dates.lastWeek = moment(currentYearOutSide)
        .subtract(1, 'weeks')
        .isSame(x._id.service_date_from, 'week')
        ? x.total_count
        : dates.lastWeek
      dates.yesterday = moment(currentYearOutSide)
        .subtract(1, 'days')
        .isSame(x._id.service_date_from, 'day')
        ? x.total_count
        : dates.yesterday
      dates.year = compareTwoDates({ ...dataInfo, dateFormat: 'YYYY', prevCount: 0 })
      dates.week = moment(x._id.service_date_from).isSame(currentYearOutSide, 'week')
        ? x.total_count
        : dates.week
      dates.prevMonth = moment(currentYearOutSide)
        .subtract(1, 'months')
        .isSame(x._id.service_date_from, 'month')
        ? x.total_count
        : dates.prevMonth
      dates.today =
        moment(currentYearOutSide).format('YYYY-MM-DD') === x._id.service_date_from
          ? x.total_count
          : dates.today
    } else {
      dates.currentMonth = compareTwoDates({
        ...dataInfo,
        dateFormat: 'YYYY-MM',
        prevCount: dates.currentMonth,
      })
      dates.year = compareTwoDates({
        ...dataInfo,
        dateFormat: 'YYYY',
        prevCount: dates.year,
      })
      dates.week = moment(x._id.service_date_from).isSame(currentYearOutSide, 'week')
        ? x.total_count + dates.week
        : dates.week
      dates.prevMonth = moment(currentYearOutSide)
        .subtract(1, 'months')
        .isSame(x._id.service_date_from, 'month')
        ? x.total_count + dates.prevMonth
        : dates.prevMonth
      dates.lastWeek = moment(currentYearOutSide)
        .subtract(1, 'weeks')
        .isSame(x._id.service_date_from, 'week')
        ? x.total_count + dates.lastWeek
        : dates.lastWeek
      dates.yesterday = moment(currentYearOutSide)
        .subtract(1, 'days')
        .isSame(x._id.service_date_from, 'day')
        ? x.total_count + dates.yesterday
        : dates.yesterday
      dates.today =
        moment(currentYearOutSide).format('YYYY-MM-DD') === x._id.service_date_from
          ? x.total_count + dates.today
          : dates.today
    }
  })

  // console.log('isDate', isDate)
  // console.log('prev_month', prev_month)
  let totall = 0
  prev_month.forEach((a, b) => {
    totall = a.total_count + totall
    // console.log('to', totall)
  })
  // console.log('totalll---', totall)
  const newData = []
  for (let prop in monthWiseData) {
    newData.push({
      monthinitial: moment(prop).format('DD-MM-YYYY'),
      month: prop,
      encounters: monthWiseData[prop],
    })
  }
  // console.log(daysMonthData)
  for (let prop in daysMonthData) {
    daysInMonthData.push({
      monthinitial: moment(prop).format('DD-MM-YYYY'),
      month: prop,
      encounters: daysMonthData[prop],
    })
  }

  return {
    dates,
    encDataByYear: data,
    monthWiseData: newData,
    daysInMonthData:
      daysInMonthData.length === 0
        ? [{ month: moment(currentYearOutSide).format('MM-DD-YYYY'), encounters: 0 }]
        : daysInMonthData,
    monthRes,
    cardsData,
    monthDataCurrYear: {
      prev_month,
      prior_month,
    },
    weekDataCurrYear: {
      prv_week,
      prior_week,
    },
  }
}
export const resetState = createAsyncThunk('encounters/resetState', () => {
  return true
})
export const getDataPractice = createAsyncThunk(
  'encounters/getDataPractice',
  async ({ tabName, range = '', name = '', activeButton }) => {
    // alert(tabName)
    try {
      let encRes
      let lastYearEncRes
      let bestYearRes
      const faciltyList = await getFacilityListData(SETTINGS_URL.GET_FACILITY_LIST)
      const rolesRes = await getFromSettingsServer(SETTINGS_URL.USER_ROLE)
      if (range != '' && name === 'Last Year') {
        startDayOfYear = moment(range.startDate).format('YYYY-MM-DD')
        endDayOfYear = moment(range.endDate).format('YYYY-MM-DD')

        startOfLastYear = moment(range.startDate)
          .startOf('year')
          .subtract(1, 'years')
          .format('YYYY-MM-DD')
        endOfLastYear = moment(range.endDate)
          .endOf('year')
          .subtract(1, 'years')
          .format('YYYY-MM-DD')

        currentYearOutSide = moment(range.endDate).format('YYYY-MM-DD')
        prevYearOutside = moment(range.endDate).subtract(1, 'years').format('YYYY-MM-DD')
      }
      if (range != '' && name === 'Last Week') {
        startDayOfYear = moment(range.startDate).format('YYYY-MM-DD')
        endDayOfYear = moment(range.endDate).format('YYYY-MM-DD')
        startOfLastYear = moment(range.startDate)
          .startOf('week')
          .subtract(1, 'weeks')
          .format('YYYY-MM-DD')
        endOfLastYear = moment(range.endDate)
          .endOf('week')
          .subtract(1, 'weeks')
          .format('YYYY-MM-DD')
        currentYearOutSide = moment(range.endDate).format('YYYY-MM-DD')
        prevYearOutside = moment(range.endDate).subtract(1, 'weeks').format('YYYY-MM-DD')
      }
      if (range != '' && name === 'Last Month') {
        startDayOfYear = moment(range.startDate).format('YYYY-MM-DD')
        endDayOfYear = moment(range.endDate).endOf('month').format('YYYY-MM-DD')
        startOfLastYear = moment(range.startDate)
          .startOf('month')
          .subtract(1, 'months')
          .format('YYYY-MM-DD')
        endOfLastYear = moment(range.endDate)
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD')
        currentYearOutSide = moment(range.endDate).format('YYYY-MM-DD')
        prevYearOutside = moment(range.endDate).subtract(1, 'months').format('YYYY-MM-DD')
      }
      if (range != '' && name === 'Current Year') {
        startDayOfYear = moment(range.startDate).format('YYYY-MM-DD')
        endDayOfYear = moment(range.endDate).format('YYYY-MM-DD')
        startOfLastYear = moment(range.startDate).subtract(1, 'years').format('YYYY-MM-DD')
        endOfLastYear = moment(range.endDate).subtract(1, 'years').format('YYYY-MM-DD')
        currentYearOutSide = moment(range.endDate).format('YYYY-MM-DD')
        prevYearOutside = moment(range.endDate).subtract(1, 'years').format('YYYY-MM-DD')
      }
      if (range != '' && name === 'Current Week') {
        startDayOfYear = moment(range.startDate).format('YYYY-MM-DD')
        endDayOfYear = moment(range.endDate).format('YYYY-MM-DD')
        startOfLastYear = moment(range.startDate).subtract(1, 'weeks').format('YYYY-MM-DD')
        endOfLastYear = moment(range.endDate).subtract(1, 'weeks').format('YYYY-MM-DD')
        currentYearOutSide = moment(range.endDate).format('YYYY-MM-DD')
        prevYearOutside = moment(range.endDate).subtract(1, 'weeks').format('YYYY-MM-DD')
      }
      if (range != '' && name === 'Current Month') {
        startDayOfYear = moment(range.startDate).format('YYYY-MM-DD')
        endDayOfYear = moment(range.endDate).format('YYYY-MM-DD')
        startOfLastYear = moment(range.startDate).subtract(1, 'months').format('YYYY-MM-DD')
        endOfLastYear = moment(range.endDate).subtract(1, 'months').format('YYYY-MM-DD')
        currentYearOutSide = moment(range.endDate).format('YYYY-MM-DD')
        prevYearOutside = moment(range.endDate).subtract(1, 'months').format('YYYY-MM-DD')
      }
      if (range != '' && name === 'custom') {
        startDayOfYear = moment(range.startDate).format('YYYY-MM-DD')
        endDayOfYear = moment(range.endDate).format('YYYY-MM-DD')
        startOfLastYear = moment(range.startDate).subtract(1, 'years').format('YYYY-MM-DD')
        endOfLastYear = moment(range.endDate).subtract(1, 'years').format('YYYY-MM-DD')
        currentYearOutSide = moment(range.endDate).format('YYYY-MM-DD')
        prevYearOutside = moment(range.endDate).subtract(1, 'years').format('YYYY-MM-DD')
      }
      if (tabName === 'encounters' && range != '') {
        bestYearRes = await getCollector(bestYearEncUrl)
        encRes = await getCollector(
          cencUrl
            .replace('{startDate}', `${moment(startDayOfYear).format('YYYY-MM-DD')}`)
            .replace('{endDate}', `${moment(endDayOfYear).format('YYYY-MM-DD')}`)
            .replace('{scopeof}', `${activeButton.toLowerCase()}`),
        )
        lastYearEncRes = await getCollector(
          clastYearEncUrl
            .replace('{startDate}', `${moment(startOfLastYear).format('YYYY-MM-DD')}`)
            .replace('{endDate}', `${moment(endOfLastYear).format('YYYY-MM-DD')}`)
            .replace('{scopeof}', `${activeButton.toLowerCase()}`),
        )
      } else if (tabName === 'rvu' && range != '') {
        bestYearRes = await getCollector(bestYearRvuUrl)
        encRes = await getCollector(
          crvuUrl
            .replace('{startDate}', `${moment(startDayOfYear).format('YYYY-MM-DD')}`)
            .replace('{endDate}', `${moment(endDayOfYear).format('YYYY-MM-DD')}`)
            .replace('{scopeof}', `${activeButton.toLowerCase()}`),
        )
        lastYearEncRes = await getCollector(
          clastYearRvuUrl
            .replace('{startDate}', `${moment(startOfLastYear).format('YYYY-MM-DD')}`)
            .replace('{endDate}', `${moment(endOfLastYear).format('YYYY-MM-DD')}`)
            .replace('{scopeof}', `${activeButton.toLowerCase()}`),
        )
      } else if (tabName === 'revenue' && range != '') {
        bestYearRes = await getCollector(bestYearRevenueUrl)
        encRes = await getCollector(
          crevenueUrl
            .replace('{startDate}', `${moment(startDayOfYear).format('YYYY-MM-DD')}`)
            .replace('{endDate}', `${moment(endDayOfYear).format('YYYY-MM-DD')}`)
            .replace('{scopeof}', `${activeButton.toLowerCase()}`),
        )
        lastYearEncRes = await getCollector(
          clastYearRevUrl
            .replace('{startDate}', `${moment(startOfLastYear).format('YYYY-MM-DD')}`)
            .replace('{endDate}', `${moment(endOfLastYear).format('YYYY-MM-DD')}`)
            .replace('{scopeof}', `${activeButton.toLowerCase()}`),
        )
      } else if (tabName === 'encounters') {
        bestYearRes = await getCollector(bestYearEncUrl)
        encRes = await getCollector(encUrl)
        lastYearEncRes = await getCollector(lastYearEncUrl)
      } else if (tabName === 'rvu') {
        bestYearRes = await getCollector(bestYearRvuUrl)
        encRes = await getCollector(rvuUrl)
        lastYearEncRes = await getCollector(lastYearRvuUrl)
      } else if (tabName === 'revenue') {
        bestYearRes = await getCollector(bestYearRevenueUrl)
        encRes = await getCollector(revenueUrl)
        lastYearEncRes = await getCollector(lastYearRevUrl)
      }
      let getDateRange = await getDates(startDayOfYear, endDayOfYear)
      let getLDateRange = await getDates(startOfLastYear, endOfLastYear)
      let lencRes = JSON.parse(JSON.stringify(encRes.result)),
        llastYearEncRes = JSON.parse(JSON.stringify(lastYearEncRes.result))
      getDateRange.length > 0 &&
        (await getDateRange.map((x) => {
          if (encRes.result.some((xx) => xx._id.service_date_from == x)) {
          } else {
            lencRes.push({
              proc_code_name: encRes?.result['0']?.proc_code_name,
              proc_group_name: encRes?.result['0']?.proc_group_name,
              total_count: 0,
              _id: {
                facility_id: encRes?.result['0']?._id?.facility_id,
                proc_code: encRes?.result['0']?._id?.proc_code,
                proc_code_group: encRes?.result['0']?._id?.proc_code_group,
                provider_id: encRes?.result['0']?._id?.provider_id,
                service_date_from: x,
              },
            })
          }
        }))
      getLDateRange.length > 0 &&
        (await getLDateRange.map((x) => {
          if (lastYearEncRes.result.some((xx) => xx._id.service_date_from == x)) {
          } else {
            llastYearEncRes.push({
              proc_code_name: lastYearEncRes?.result['0']?.proc_code_name,
              proc_group_name: lastYearEncRes.result['0']?.proc_group_name,
              total_count: 0,
              _id: {
                facility_id: lastYearEncRes.result['0']?._id.facility_id,
                proc_code: lastYearEncRes.result['0']?._id.proc_code,
                proc_code_group: lastYearEncRes.result['0']?._id.proc_code_group,
                provider_id: lastYearEncRes.result['0']?._id.provider_id,
                service_date_from: x,
              },
            })
          }
        }))
      // console.log(lencRes, llastYearEncRes)
      // console.log({ ...filterData(lencRes), ...lastYearData(llastYearEncRes) })
      return {
        ...filterData(lencRes),
        ...lastYearData(llastYearEncRes),
        bestYearAndMonthData: bestYearRes?.result,
        faciltyList: faciltyList?.status === 'success' ? faciltyList?.results : [],
        userRoles: rolesRes?.status === 'success' ? rolesRes?.results?.map((x) => x.groupname) : [],
        name: name,
        currentDateValue: {
          startDate: moment(range.startDate).format('YYYY-MM-DD'),
          endDate: moment(range.endDate).format('YYYY-MM-DD'),
        },
      }
    } catch (err) {
      console.log(err)
      return false
    }
  },
)

export const getSwitcherTabData = createAsyncThunk(
  'encounters/getSwitcherTabData',
  async (switcherName) => {
    const encounterApiUrl = COLLECTOR_URL.GET_BEST_YEAR_ENCOUNTER.replace(
      '{type}',
      `${switcherName?.switcherTabName}`,
    )
    const rvuApiUrl = COLLECTOR_URL.GET_BEST_YEAR_RVU.replace(
      '{type}',
      `${switcherName?.switcherTabName}`,
    )
    const revenueApiUrl = COLLECTOR_URL.GET_BEST_YEAR_REVENUE.replace(
      '{type}',
      `${switcherName?.switcherTabName}`,
    )

    try {
      let response
      if (switcherName?.switcherTabName && switcherName?.activeTab === 'encounters') {
        response = await getCollector(encounterApiUrl)
        return { bestYearAndMonthDataPractice: response?.result }
      }
      if (switcherName?.switcherTabName && switcherName?.activeTab === 'rvu') {
        response = await getCollector(rvuApiUrl)
        return { bestYearAndMonthDataPractice: response?.result }
      }
      if (switcherName?.switcherTabName && switcherName?.activeTab === 'revenue') {
        response = await getCollector(revenueApiUrl)
        return { bestYearAndMonthDataPractice: response?.result }
      }
    } catch (err) {
      console.log('err', err)
      return false
    }
  },
)
