import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getPatientsVisitDetailsID,
  getProgressNoteConvertV2,
  getProgressNoteImage,
  getProgressNoteTemplates,
  getVisitCharts,
  getVisitDiagnosis,
  getVisitProcedures,
} from 'src/helper/backendHelper'
import { fetchMips } from '../../helper/mipsHelper'

export const getInitialPayload = createAsyncThunk(
  'getProgressNote/initialPayload',
  async (visitid, thunkAPI) => {
    try {
      const resProgressNoteTemplate = await getProgressNoteTemplates()
      const resDiagnosis = await getVisitDiagnosis(visitid)
      const resProcedures = await getVisitProcedures(visitid)
      const resVisitDetail = await getPatientsVisitDetailsID(visitid)
      const resCurrentChart = await getVisitCharts(visitid)
      const fetchedMips = await fetchMips({
        charge_id: visitid,
      })

      let chartData = resCurrentChart?.data

      if (!chartData?.from_v2) {
        const v2ChartRes = await getProgressNoteConvertV2(visitid)

        if (v2ChartRes) {
          chartData = v2ChartRes
        }
      }

      if (resProgressNoteTemplate?.status === 500) {
        return thunkAPI.rejectWithValue('Server Error')
      } else {
        let initialObject = {}
        if (resProgressNoteTemplate?.status === 'success') {
          let {
            user: { features },
          } = thunkAPI.getState()
          initialObject = {
            features: features || [],
            templates: resProgressNoteTemplate?.results,
            mips: fetchedMips,
            visitDetail: resVisitDetail?.result?.[0],
            diagnosisData: resDiagnosis?.results,
            proceduresData: resProcedures?.results,
          }
        }

        if (resCurrentChart?.status === 'success' && resVisitDetail?.status === 'success') {
          initialObject = {
            ...initialObject,
            chartData: chartData,
          }
        }

        return initialObject
      }
    } catch (err) {
      return err?.message
    }
  },
)

export const getChartData = createAsyncThunk(
  //action type string
  'getProgressNote/chartData',
  // callback function
  async (visitid, thunkAPI) => {
    const resImg = await getProgressNoteImage(visitid)

    if (resImg?.status === 500) {
      return thunkAPI.rejectWithValue('Server Error')
    } else {
      let responseObject = {}

      if (resImg?.status === 'success') {
        responseObject = {
          progressImg: resImg?.data,
        }
      }
      return responseObject
    }
  },
)
