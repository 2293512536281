import { createAsyncThunk } from '@reduxjs/toolkit'
import { Base64 } from 'js-base64'
import { postCollector } from '../../apiHelper/collectorApi'
import { getFromSettingsServer, putDataToSettingsServer } from 'src/apiHelper/settingsApi'
import {
  currentFeatures,
  currentPermissions,
  currentPractice,
  currentProvider,
  currentRoles,
  currentSettings,
  getPractice,
  getProviders,
} from 'src/helper/backendHelper'
import { COLLECTOR_URL } from '../../apiHelper/apiRoute/collector'
import { SETTINGS_URL } from '../../apiHelper/apiRoute/settings'

export const login = createAsyncThunk('user/login', async (credential) => {
  try {
    const data = {
      name: Base64.encode(credential.username),
      password: Base64.encode(credential.password),
      platform: process.env.REACT_APP_PLATFORM,
      appVersion: process.env.REACT_APP_VERSION,
      appName: process.env.REACT_APP_NAME,
    }
    const response = await postCollector(COLLECTOR_URL.LOGIN, data)

    if (response?.authToken) {
      let authUser = {
        authRefreshToken: response?.authRefreshToken,
        authToken: response?.authToken,
        user: response?.user,
        roles: response?.roles,
        permissions: response?.permissions,
      }
      localStorage.setItem('authUser', JSON.stringify(authUser))
      const practiceList = await getFromSettingsServer(SETTINGS_URL.GET_PRACTICE).then(
        (res) => res?.results,
      )
      const providerList = await getFromSettingsServer(SETTINGS_URL.GET_PROVIDER).then(
        (res) => res?.results,
      )
      return { ...response, practiceList, providerList }
    } else if (response?.mfatoken) {
      return {
        ...response,
        isMFAEnabled: true,
        username: credential?.userName,
      }
    } else {
      return response
    }
  } catch (err) {
    console.log('login failed::', err.message)
    return err.message
  }
})

export const mfaLogin = createAsyncThunk(
  //action type string
  'user/mfaLogin',
  // callback function
  async (credentials) => {
    try {
      let verificationDetails = {
        code: credentials.code,
        'mfa-token': credentials['mfa-token'],
      }
      const loginRes = await postCollector(COLLECTOR_URL.VERIFY_OTP, verificationDetails)
      if (loginRes?.authToken) {
        let authUser = {
          authRefreshToken: loginRes?.authRefreshToken,
          authToken: loginRes?.authToken,
          user: loginRes?.user,
          roles: loginRes?.roles,
          permissions: loginRes?.permissions,
        }
        localStorage.setItem('authUser', JSON.stringify(authUser))

        const practiceList = await getFromSettingsServer(SETTINGS_URL.GET_PRACTICE).then(
          (res) => res?.results,
        )
        const providerList = await getFromSettingsServer(SETTINGS_URL.GET_PROVIDER).then(
          (res) => res?.results,
        )
        return { ...loginRes, practiceList, providerList }
      } else {
        return loginRes
      }
    } catch (err) {
      console.log('MFA login failed::', err.message)
      return err.message
    }
  },
)

export const userSettingGet = createAsyncThunk(
  //action type string
  'user/usersettingsget',
  // callback function
  async () => {
    const settingsRes = await getFromSettingsServer(SETTINGS_URL.USER_SETTINGS)
      .then((res) => res?.results)
      .catch((err) => {})
    return {
      settingsRes,
    }
  },
)
export const userSettingPut = createAsyncThunk(
  //action type string
  'user/usersettingput',
  // callback function
  async (data) => {
    return putDataToSettingsServer(SETTINGS_URL.USER_SETTINGS, data)
      .then((res) => data)
      .catch((err) => null)
  },
)

export const providerLogin = createAsyncThunk(
  //action type string
  'user/providerLogin',
  // callback function
  async (credentials) => {
    let authUser = localStorage.getItem('authUser')
    let authObj

    if (typeof authUser === 'string') {
      authObj = JSON.parse(authUser)
    }

    const logRes = await postCollector(COLLECTOR_URL.PROVIDER_LOGIN, {
      practice_id: credentials.practice_id,
      provider_id: credentials.provider_id,
    })

    if (logRes?.status === 'success' && logRes?.result !== null) {
      let newToken = await { ...authObj, authToken: logRes?.result }
      await localStorage.setItem('authUser', JSON.stringify(newToken))

      const providerRes = await getFromSettingsServer(SETTINGS_URL.CURRENT_PROVIDER)
        .then((res) => res?.results)
        .catch((err) => {})
      const practiceRes = await getFromSettingsServer(SETTINGS_URL.CURRENT_PRACTICE)
        .then((res) => res?.result)
        .catch((err) => {})
      const settingsRes = await getFromSettingsServer(SETTINGS_URL.USER_SETTINGS)
        .then((res) => res?.results)
        .catch((err) => {})
      const rolesRes = await getFromSettingsServer(SETTINGS_URL.USER_ROLE)
        .then((res) => res?.results)
        .catch((err) => {})
      const permissionsRes = await getFromSettingsServer(SETTINGS_URL.USER_PERMISSION)
        .then((res) => res?.results)
        .catch((err) => {})
      const featuresRes = await getFromSettingsServer(SETTINGS_URL.USER_FEATURE)
        .then((res) => res)
        .catch((err) => {})
      localStorage.setItem('currentPractice', JSON.stringify(practiceRes))
      localStorage.removeItem('actTab')

      return {
        ...newToken,
        practiceRes,
        providerRes,
        settingsRes,
        rolesRes,
        permissionsRes,
        featuresRes,
      }
    } else {
      return 'Server Error'
    }
  },
)

export const getProvider = createAsyncThunk('user/getProvider', async () => {
  const providerRes = await getFromSettingsServer(SETTINGS_URL.CURRENT_PROVIDER)
    .then((res) => res?.results)
    .catch((err) => {
      console.error(err)
      return null
    })

  return providerRes
})

export const rememberAuth = createAsyncThunk(
  //action type string
  'user/rememberAuth',
  // callback function
  async (token, thunkAPI) => {
    try {
      const practiceRes = await getPractice().then((res) => res?.results)
      const providerRes = await getProviders().then((res) => res?.results)

      const settingsRes = await currentSettings().then((res) => res?.results)
      const rolesRes = await currentRoles().then((res) => res?.results)
      const permissionsRes = await currentPermissions().then((res) => res?.results)
      const featuresRes = await currentFeatures().then((res) => res)

      const currentProviderRes = await currentProvider()
        .then((res) => res?.results)
        .catch(console.error)
      const currentPracticeRes = await currentPractice()
        .then((res) => res?.result)
        .catch(console.error)

      if (practiceRes?.length > 0 && providerRes?.length) {
        return {
          ...token,
          practiceRes,
          providerRes,
          currentProviderRes,
          currentPracticeRes,
          settingsRes,
          rolesRes,
          permissionsRes,
          featuresRes,
        }
      } else {
        return thunkAPI.rejectWithValue('Server Error')
      }
    } catch (e) {
      if (e?.response?.status === 401) {
        return thunkAPI.rejectWithValue('Unauthorized')
      }
    }
  },
)
