import { createAsyncThunk } from '@reduxjs/toolkit'
import { getFromSettingsServer } from '../../apiHelper/settingsApi'
import { SETTINGS_URL } from '../../apiHelper/apiRoute/settings'

export const getDemographics = createAsyncThunk('demographics', async () => {
  try {
    const demographics = await getFromSettingsServer(SETTINGS_URL.DEMOGRAPHICS)
    return {
      ...demographics,
    }
  } catch (err) {
    console.log('Error getting demographics', err)
    return false
  }
})
