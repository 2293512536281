import axios from 'axios'

export const environment = {
  production: false,
  HostName: process.env.REACT_APP_COLLECTOR_BASEURL,
  Settings_Host: process.env.REACT_APP_SETTINGS_BASEURL,
  Socket_Host: process.env.REACT_APP_SOCKET_BASEURL,
  Fusion_Host: process.env.REACT_APP_FUSION_BASEURL,
}

export const COLLECTOR_URL = environment.HostName
export const SETTINGS_URL = environment.Settings_Host
export const SOCKET_URL = environment.Socket_Host
export const FUSION_URL = environment.Fusion_Host

export const getToken = async () => {
  const authUser: String | null = localStorage.getItem('authUser')
  let authObj: any
  if (typeof authUser === 'string') {
    authObj = JSON.parse(authUser)
  }
  return authObj?.authToken
}

export const getRefreshToken = async () => {
  const authUser: String | null = localStorage.getItem('authUser')
  let authObj: any
  if (typeof authUser === 'string') {
    authObj = JSON.parse(authUser)
  }
  return authObj?.authRefreshToken
}

export const getUserFromLocal = async () => {
  const authUser: String | null = localStorage.getItem('authUser')
  let authObj: any
  if (typeof authUser === 'string') {
    authObj = JSON.parse(authUser)
  }
  return authObj?.user
}

const onRequest = async (config: any) => {
  const token = await getToken()

  if (token) {
    config.headers['authorization'] = token
  }

  return config
}

const onFusionRequest = async (config: any) => {
  const token = await getToken()

  if (token) {
    config.headers['authorization'] = 'Bearer ' + token
  }

  return config
}

const onRequestError = (error: any) => {
  return Promise.reject(error)
}

const onResponse = (response: any) => {
  return response
}

const onResponseError = async (error: any) => {
  if (error.response) {
    // Access Token was expired
    if (
      error.response.status === 401 &&
      error.response.data.message === 'Error: Authorization-expired'
    ) {
      try {
        const token = await getToken()
        const refreshToken = await getRefreshToken()
        const user = await getUserFromLocal()

        const loginRes = await axios.post(`${COLLECTOR_URL}/refreshtoken`, {
          refreshToken: refreshToken,
          authToken: token,
          userId: user?._id,
        })

        if (loginRes?.data?.status === 'failed') {
          localStorage.removeItem('authUser')
          if (window) {
            window.location.replace('/')
          }

          return Promise.reject(loginRes?.data?.message)
        } else {
          let tokenStr = localStorage.getItem('authUser')

          let authObj: any

          if (typeof tokenStr === 'string') {
            authObj = JSON.parse(tokenStr)
          }

          localStorage.setItem(
            'authUser',
            JSON.stringify({
              ...authObj,
              authToken: loginRes?.data?.authToken,
            }),
          )

          error.config.headers['authorization'] = loginRes?.data?.authToken

          let retry = await axios.request(error?.config)

          return retry
        }
      } catch (error) {
        localStorage.removeItem('authUser')

        if (error?.response?.data?.error === 'Authorization-noheader') {
          if (window) {
            window.location.replace('/')
          }
        }

        return Promise.reject(error)
      }
    } else {
      return Promise.reject(error)
    }
  } else {
    return Promise.reject(error)
  }
}

export const setupInterceptorsTo = (axiosInstance: any, type: String = '') => {
  if (type === 'FUSION') {
    axiosInstance.interceptors.request.use(onFusionRequest, onRequestError)
  } else {
    axiosInstance.interceptors.request.use(onRequest, onRequestError)
  }
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}
