import { createSlice } from '@reduxjs/toolkit'
import { getProviderHoldList } from './providerHoldThunk'

const initialState = {
  activeButton: 'Provider',
  encounters: [],
  inPatients: [],
  userRoles: [],
  isloading: false,
  isError: false,
  errorMsg: '',
}
const providerHoldSlice = createSlice({
  name: 'providerHold',
  initialState,
  reducers: {
    setActiveDate: (state, action) => {
      state.activeDate = action.payload
    },

    setCensusFacility: (state, action) => {
      state.censusFacility = action.payload
    },

    setFacilitiesList: (state, action) => {
      state.facilitiesList = action.payload
    },

    setActiveButton: (state, action) => {
      state.activeButton = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderHoldList.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getProviderHoldList.fulfilled, (state, action) => {
        state.isloading = false
        // state.visitsByDate = action.payload?.visitsByDate
        // state.rvuTotal = action.payload?.rvuTotal ? action.payload?.rvuTotal : 0
        // state.paymentTotal = action.payload?.paymentTotal ? action.payload?.paymentTotal : 0
        // state.providerHoldCount = action.payload?.providerHoldCount
        //   ? action.payload?.providerHoldCount
        //   : 0

        state.userRoles = action.payload.userRoles
        state.inPatients = action.payload?.inPatients ? action.payload?.inPatients : []
        state.encounters = action.payload?.encounters ? action.payload?.encounters : []
        // state.diagnosisList = action.payload?.diagnosisList ? action.payload?.diagnosisList : []
        // state.procedureCodes = action.payload?.procedureCodes ? action.payload?.procedureCodes : []
        // state.diagnosisTotalCount = action.payload?.diagnosisTotalCount
        //   ? action.payload?.diagnosisTotalCount
        //   : 0
        // state.serviceDate = action.payload?.serviceDate
        //   ? action.payload?.serviceDate
        //   : initialState.serviceDate
        // state.visitStatuses = action?.payload?.visitStatuses?.results[0]?.values

        // state.facilitiesList = action.payload?.facilitiesList ? action.payload?.facilitiesList : []
        // state.allFacilitiesList = action.payload?.allFacilitiesList
        //   ? action.payload?.allFacilitiesList
        //   : []
      })
      .addCase(getProviderHoldList.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
  },
})

export const { setActiveDate, setCensusFacility, setFacilitiesList, setActiveButton } =
  providerHoldSlice.actions
export const selectProviderList = (state) => state.providerHoldSlice
export default providerHoldSlice.reducer
