import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCollector } from 'src/apiHelper/collectorApi'
import { COLLECTOR_URL } from '../../apiHelper/apiRoute/collector'

export const getPatient = createAsyncThunk('patient/getById', async (id) => {
  try {
    const url = COLLECTOR_URL.GET_VISIT_DETAILS.replace('{id}', id)
    const patientDetailUrl = COLLECTOR_URL.GET_PATIENT_DETAILS_BY_ID.replace('{visitId}', id)
    const getVisitDetails = await getCollector(url)
    const getPatientDetails = await getCollector(patientDetailUrl)

    if (getPatientDetails?.status === 500) {
      return { status: 'failure', message: 'Internal server error' }
    } else {
      if (getPatientDetails?.status === 'success') {
        return {
          visitDetails: getVisitDetails?.resData || [],
          patientDetails: getPatientDetails?.result[0] || {},
        }
      }
    }
  } catch (err) {
    return err?.message
  }
})
