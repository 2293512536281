export const COLLECTOR_URL = {
  LOGIN: 'authenticate/v3',
  RESET_OTP_SEND: 'resetpassword/sendOTP',
  RESET_OTP_VALIDATE: 'resetpassword/validateOTP',
  RESET_PASSWORD_UPDATE: 'resetpassword/updatePassword',
  CHANGE_PASSWORD: 'resetpassword/changePassword',
  CENSUS_LIST:
    'dailycensus/list?servicedate={serviceDate}&saltvisits=true&teamvisits=true&favdefault=true&facility_id={facility_id}&showRecentlyDischarged={showRecentlyDischarged}',
  CENSUS_LIST_OLD:
    'dailycensus/visits?servicedate={serviceDate}&saltvisits=true&teamvisits=true&favdefault=true',
  GET_PROGRESS_NOTE: 'charges/{visitID}/chart',
  GET_PROGRESS_NOTE_IMG: 'charges/{visitID}/chart/image?',
  PROVIDER_LOGIN: 'providerlogin',
  ADD_PATIENT: 'patients/addnew',
  CHANGE_CPT: `charges/{visitID}/procedures`,
  GET_PROVIDER_HOLD_COUNT: 'dailycensus/providerholdCount',
  GET_PATIENT_SEARCH_DATA:
    'dailycensus/patients?&q={searchString}&startIndex={startIndex}&recordCount={recordCount}&alphabet={alphabet}',
  ADD_ENCOUNTER: 'dailycensus/visits',
  GET_PATIENT_DETAILS_BY_ID: 'dailycensus/visits/{visitId}',
  CHANGE_DIAGNOSIS_CODE: 'charges/{visitId}/diagnosiscodes',
  RECALL_SUBMISSION: 'dailycensus/visits/{visitId}/recallbill',
  GET_VISIT_DETAILS: 'dailycensus/visits/{id}/series',
  ADDENDDUM_TEXT: '/charges/{visitId}/chart/addendums',
  BULK_UPDATE: 'dailycensus/bulkvoid',
  GET_ENCOUNTER_HISTORY: '/dailycensus/visits/{visitId}/series',
  GET_ENCOUNTER_HISTORY_v2: '/dailycensus/visits/{visitId}/series/v2?&year={year}',
  MFA_LOGIN_MOBILE: 'mfa/otp',
  MFA_LOGIN_MAIL: 'mfa/mailOtp',
  VERIFY_OTP: 'verify/otp',
  SAVE_SIGN: 'charges/{visitID}/chart/sign',
  EDIT_PATIENT: '/patients/update-patient/{patient_id}',
  GET_PROVIDER_HOLD_DATA:
    'v2/providerHold?startIndex={startIndex}&recordCount={recordCount}&scope={scope}',
  GET_PATIENT_HOLD_DATA: 'v2/providerHold/{patientId}',
  MARK_DISCHARGE: 'dailycensus/visits/{visitId}/clone',
  IMAGE_UPLOAD: '/dailycensus/image',
  CLONE: 'dailycensus/visits/{visitId}/clone',
  VISIT_STATUS: 'dailycensus/visits/{visitId}/visitstatus',
  VISIT_STATUS_KEY: 'dailycensus/visits/{visitId}/statuses',
  CALL_CONFIRM_VISIT: '/dailycensus/visits/{charge_id}/clone',
  BILL_SUBMIT: 'dailycensus/visits/{charge_id}/submitbill',
  BILL_STATUS_CHANGE: 'dailycensus/visits/{visit_id}/resolve',

  BULK_RECALL_SUBMISSION: 'dailycensus/v2/visits/bulkrecallbill',
  BULK_CLONE: 'dailycensus/v2/visits/bulkvisitclone',
  BULK_VISIT_STATUS: 'dailycensus/v2/visits/bulkvisitstatus',
  BULK_VISIT_STATUS_KEY: 'dailycensus/v2/visits/bulkstatuses',
  BULK_CALL_CONFIRM_VISIT: '/dailycensus/v2/visits/bulkvisitclone',
  BULK_CALL_COSIGN: '/dailycensus/visits/cosign',
  BULK_BILL_SUBMIT: 'dailycensus/v2/visits/bulksubmitbill',
  BULK_BILL_STATUS_CHANGE: 'dailycensus/v2/visits/bulkresolve',
  GET_ENCOUNTER_BY_YEAR: '/v2/practiceiq/encounters/{startDate}/{endDate}?scope={scopeof}',
  GET_RVU_BY_YEAR: '/v2/practiceiq/rvu/{startDate}/{endDate}?scope={scopeof}',
  GET_REVENUE_BY_YEAR: 'v2/practiceiq/revenue/{startDate}/{endDate}?scope={scopeof}',
  DAILY_SUMMARY: 'dailycensus/visits/dailysummary?date_from={startDate}&date_to={endDate}',
  DAILY_GROUP_SUMMARY:
    'dailycensus/visits/dailygroupsummary?date_from={startDate}&date_to={endDate}',
  GET_CPT_BY_VISIT_ID: 'dailycensus/visits/{visitId}/procedures',
  GET_DIAGNOSIS_BY_VISIT_ID: 'dailycensus/visits/{visitId}/diagnosis',
  GET_PROFILE_PICTURE: 'dailycensus/files/image/{fileName}',
  GET_BEST_YEAR_REVENUE: '/v2/practiceiq/revenue/top/{type}',
  GET_BEST_YEAR_ENCOUNTER: '/v2/practiceiq/encounters/top/{type}',
  GET_BEST_YEAR_RVU: '/v2/practiceiq/rvu/top/{type}',
  PRACTICEOVERVIEW_PATIENTS: '/v2/dashboard/practice/patients/{currentDate}/{type}',
  PRACTICEOVERVIEW_ENCOUNTERS: '/v2/dashboard/practice/encounters/{currentDate}/{type}',
  PRACTICEOVERVIEW_REVENUE: '/v2/dashboard/practices/revenue/{currentDate}/{type}',
  PRACTICEOVERVIEW_RVU: '/v2/dashboard/practice/rvu/{currentDate}/{type}',
  DELETE_UPLOAD: 'dailyCensus/visits/{file_name}/files',
  PATIENT_FAVORITE: 'dailycensus/patients/{id}/favorite',
  GET_DOCUMENT_BY_FACILITY:
    '/dailycensus/documents?service_date={service_date}&facilityIds[]={facilityIds}',
}
