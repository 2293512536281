import { createSlice } from '@reduxjs/toolkit'
import { getContactList, getMessageList, loadMore } from './messageThunk'

const initialState = {
  messagesList: [],
  archiveList: [],
  selectedChat: {},
  contactList: [],
  practiceUser: [],
  supportUser: [],
  distributionGroup: {},
  messages: [],
  redirectedFrom: '',
  providerMsgList: [],
  activeTab: {
    selected: 'Provider Hold',
    tabs: [
      {
        name: 'Provider Hold',
      },
      {
        name: 'Archived',
      },
    ],
  },
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setSelectedChat: (state, action) => {
      state.selectedChat = action?.payload
    },
    setPracticeUsers: (state, action) => {
      state.practiceUser = action?.payload
    },
    setSupportUsers: (state, action) => {
      state.supportUser = action?.payload
    },
    setMessages: (state, action) => {
      state.messages = action?.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action?.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMessageList.pending, (state, action) => {})
      .addCase(getMessageList.fulfilled, (state, action) => {
        if (action?.payload?.type === 'Provider Hold') {
          state.providerMsgList = [...action?.payload?.data]
        } else if (action?.payload?.type === 'Archived') {
          state.archiveList = [...action?.payload?.data]
          // state.unreadList = [...action?.payload?.unread]
        }
      })

      .addCase(getMessageList.rejected, (state, action) => {})
      .addCase(loadMore.pending, (state, action) => {})
      .addCase(loadMore.fulfilled, (state, action) => {
        if (action?.payload?.type === 'messages') {
          state.messagesList = [...state?.messagesList, ...action?.payload?.data]
        } else if (action?.payload?.type === 'archive') {
          state.archiveList = [...state?.archiveList, ...action?.payload?.data]
        }
      })

      .addCase(loadMore.rejected, (state, action) => {})

      .addCase(getContactList.pending, (state, action) => {})
      .addCase(getContactList.fulfilled, (state, action) => {
        state.contactList = action?.payload?.data
        state.practiceUser = action?.payload?.practiceUser
        state.supportUser = action?.payload?.supportUser
        state.distributionGroup = action?.payload?.distributionGroup
      })

      .addCase(getContactList.rejected, (state, action) => {})
  },
})

export const { setSelectedChat, setPracticeUsers, setSupportUsers, setMessages, setActiveTab } =
  messageSlice.actions
export default messageSlice.reducer
