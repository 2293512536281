import { createAsyncThunk } from '@reduxjs/toolkit'
import { getFromSettingsServer } from 'src/apiHelper/settingsApi'
import {
  getEncounterData,
  getPatientsData,
  getRevenueData,
  getRvuData,
  getInitialprocedures,
} from 'src/helper/backendHelper'
import { SETTINGS_URL } from '../../apiHelper/apiRoute/settings'

export const getPracticeOverview = createAsyncThunk(
  'dashboard/paracticeoverview',
  async (reqData, thunkAPI) => {
    const { currentDate, type } = reqData

    const resPatients = await getPatientsData(currentDate, type)
    const resEncounter = await getEncounterData(currentDate, type)
    const resRevenue = await getRevenueData(currentDate, type)
    const resRvu = await getRvuData(currentDate, type)

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    if (resPatients?.status === 500) {
      return thunkAPI.rejectWithValue('Server Error')
    } else {
      let responce = {}
      if (resPatients?.status === 'SUCCESS') {
        let patientCount = 0,
          prevPatientCount = 0,
          ComparisonPercentage = 0

        let tempPatients = []
        resPatients?.result[0]?.previous?.forEach((row) => {
          prevPatientCount += row.total_count
        })
        resPatients?.result[1]?.current?.forEach((row) => {
          patientCount += row.total_count
          tempPatients.push(row.total_count)
        })

        ComparisonPercentage = ((patientCount - prevPatientCount) / prevPatientCount) * 100

        responce = {
          ...responce,
          patients: {
            totalPatientCount: patientCount,
            prevTotalCount: prevPatientCount,
            data: tempPatients,
            comparisonPercentage: ComparisonPercentage
              ? Math.round((ComparisonPercentage + Number.EPSILON) * 100) / 100
              : 0,
          },
        }
      }

      if (resEncounter?.status === 'SUCCESS') {
        let encountersCount = 0,
          prevEncountersCount = 0,
          ComparisonPercentage = 0

        let tempEncounter = []
        resEncounter?.result[0]?.previous?.forEach((row) => {
          prevEncountersCount += row.total_count
        })
        resEncounter?.result[1]?.current?.forEach((row) => {
          encountersCount += row.total_count
          tempEncounter.push(row.total_count)
        })

        ComparisonPercentage = ((encountersCount - prevEncountersCount) / prevEncountersCount) * 100

        responce = {
          ...responce,
          encounters: {
            totalencountersCount: encountersCount,
            prevTotalCount: prevEncountersCount,
            data: tempEncounter,
            comparisonPercentage: ComparisonPercentage
              ? Math.round((ComparisonPercentage + Number.EPSILON) * 100) / 100
              : 0,
          },
        }
      }

      if (resRevenue?.status === 'SUCCESS') {
        let revenueCount = 0,
          prevRevenueCount = 0,
          ComparisonPercentage = 0

        let tempRevenue = []
        resRevenue?.result[0]?.previous?.forEach((row) => {
          prevRevenueCount += row.total_count
        })
        resRevenue?.result[1]?.currrent?.forEach((row) => {
          revenueCount += row.total_count
          tempRevenue.push(row.total_count)
        })

        ComparisonPercentage = ((revenueCount - prevRevenueCount) / prevRevenueCount) * 100

        responce = {
          ...responce,
          revenue: {
            totalrevenueCount: formatter.format(revenueCount),
            prevTotalCount: prevRevenueCount,
            data: tempRevenue,
            comparisonPercentage: ComparisonPercentage
              ? Math.round((ComparisonPercentage + Number.EPSILON) * 100) / 100
              : 0,
          },
        }
      }
      if (resRvu?.status === 'SUCCESS') {
        let rvuCount = 0,
          prevrvuCount = 0,
          ComparisonPercentage = 0

        let temprvu = []
        resRvu?.result[0]?.previous?.forEach((row) => {
          prevrvuCount += row.total_count_of
        })
        resRvu?.result[1]?.current?.forEach((row) => {
          rvuCount += row.total_count_of
          temprvu.push(row.total_count_of)
        })

        ComparisonPercentage = ((rvuCount - prevrvuCount) / prevrvuCount) * 100

        responce = {
          ...responce,
          rvu: {
            totalrvuCount: Math.round((rvuCount + Number.EPSILON) * 100) / 100,
            prevTotalCount: Math.round((prevrvuCount + Number.EPSILON) * 100) / 100,
            data: temprvu,
            comparisonPercentage: ComparisonPercentage
              ? Math.round((ComparisonPercentage + Number.EPSILON) * 100) / 100
              : 0,
          },
        }
      }

      return responce
    }
  },
)

export const getFacilities = createAsyncThunk('census/getFacilities', async () => {
  try {
    const resFacilities = await getFromSettingsServer(SETTINGS_URL.FACILITIES_BY_PROVIDER)

    if (resFacilities?.status === 500) {
      return 'Server Error'
    } else {
      if (resFacilities?.status === 'success') {
        // console.log(resFacilities.results)
        return {
          allFacilitiesList: resFacilities.results.filter((fac) => fac.enabled),
        }
      }
    }
  } catch (err) {
    console.log('getting error in getFacilities', err)
    return false
  }
})

export const getInitialProcedure = createAsyncThunk('census/getInitialProcedure', async () => {
  try {
    const resInitialProcedure = await getInitialprocedures()
    // console.log('getFacilities', resFacilities)
    if (resInitialProcedure?.status === 500) {
      return 'Server Error'
    } else {
      if (resInitialProcedure?.status === 'success') {
        // console.log(resFacilities.results)
        return {
          initialProcedure: resInitialProcedure.results.map((x) => {
            return x.code
          }),
        }
      }
    }
  } catch (err) {
    console.log('getting error in getFacilities', err)
    return false
  }
})
