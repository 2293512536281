import { createSlice } from '@reduxjs/toolkit'
import { verifyForgotpwdOtp, verifyUsername } from './forgotPasswordThunk'

const initialState = {}

export const forgotPassword = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload
    },
    closeError: (state) => {
      state.isError = false
      state.errorMsg = ''
    },
    closeLoading: (state) => {
      state.isloading = false
    },
    setError: (state, action) => {
      state.isloading = false
      state.isError = true
      state.errorMsg = action.payload
    },
    resetData: (state) => {
      state.userName = null
      state.isloading = false
      state.isError = false
      state.errorMsg = ''
      state.isOtpVerified = false
      state.otp = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUsername.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(verifyUsername.fulfilled, (state, action) => {
        state.isloading = false
        state.userName = action.payload?.username ? action.payload?.username : null
      })
      .addCase(verifyUsername.rejected, (state, action) => {
        state.isloading = false
        state.isError = true

        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(verifyForgotpwdOtp.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(verifyForgotpwdOtp.fulfilled, (state, action) => {
        state.isloading = false
        state.isOtpVerified = true
        state.otp = action.payload.otp
      })
      .addCase(verifyForgotpwdOtp.rejected, (state, action) => {
        state.isloading = false
        state.isError = true

        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
  },
})

export const { setUserName, closeError, closeLoading, setError, resetData } = forgotPassword.actions

export default forgotPassword.reducer
