import axios from 'axios'
import { setupInterceptorsTo } from './helper.ts'

const settingsApi = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_SETTINGS_BASEURL,
    headers: {
      'Content-Type': 'application/json',
      // Origin: "claimocity",
    },
  }),
)

export async function getFromSettingsServer(url, config = {}) {
  return await settingsApi.get(url, { ...config }).then((response) => response.data)
}

export async function postToSettingsServer(url, data, config = {}) {
  return settingsApi.post(url, { ...data }, { ...config }).then((response) => response.data)
}

export async function postToSettingsFavDiag(url, data, config = {}) {
  return settingsApi.post(url, [...data], { ...config }).then((response) => response.data)
}

export async function putDataToSettingsServer(url, data, config = {}) {
  return settingsApi.put(url, { ...data }, { ...config }).then((response) => response.data)
}

export async function putDataToSettingsServerAsArray(url, data, config = {}) {
  return settingsApi.put(url, [...data], { ...config }).then((response) => response.data)
}

export async function putSettingsForSingleParam(url, config = {}) {
  return settingsApi.put(url, { ...config }).then((response) => response.data)
}

export async function deleteFromSettingsServer(url, config = {}) {
  return await settingsApi.delete(url, { ...config }).then((response) => response.data)
}

export async function getFacilityListData(url, config = {}) {
  return await settingsApi.get(url, { ...config }).then((response) => response.data)
}

export async function getSettings(url, data, config = {}) {
  return settingsApi.get(url, { ...config }).then((response) => response.data)
}

export async function getCodeTemplates(url, data, config = {}) {
  return settingsApi.get(url, { ...config }).then((response) => response.data)
}

export async function getChartValidationDirectives(url, config = {}) {
  return settingsApi.get(url, { ...config }).then((response) => response.data)
}
