export const getInpatientEncounter = (visit, statuses, settings) => {
  let inPatients = []
  let encounters = []

  let tempVisit = [...visit]

  try {
    for (let tmp of tempVisit) {
      if (tmp?.activities && tmp?.activities.length > 0) {
        tmp = {
          ...tmp,
          last_activity: tmp.activities[tmp.activities.length - 1].activity_date,
        }
      } else {
        tmp = { ...tmp, last_activity: '' }
      }
      if (
        tmp.diagnosis_code_primary &&
        Array.isArray(tmp.diagnosis_code_primary) &&
        tmp.diagnosis_code_primary.length > 0
      ) {
        if (tmp.diagnosis_code_primary && tmp.diagnosis_code_primary[0]) {
          tmp = {
            ...tmp,
            diagnosis_code_primary: {
              description:
                tmp.diagnosis_code_primary && tmp.diagnosis_code_primary[0]
                  ? tmp.diagnosis_code_primary?.[0].description
                  : '',
              code: tmp.diagnosis_codes?.[0]
                ? tmp.diagnosis_codes?.[0]
                : tmp.diagnosis_code_primary.code,
            },
          }
        }
      } else if (
        (!tmp.diagnosis_code_primary || tmp.diagnosis_code_primary.length == 0) &&
        tmp.diagnosis_codes &&
        tmp.diagnosis_codes.length > 0
      ) {
        tmp = {
          ...tmp,
          diagnosis_code_primary: {
            description: '',
            code: tmp.diagnosis_codes?.[0] ? tmp.diagnosis_codes[0] : '',
          },
        }
      }

      if (tmp.rendering_provider_name) {
        let names = tmp.rendering_provider_name.split(' ')
        let firstname = names?.[1]
        let lastname = names?.[0]
        tmp = { ...tmp, rendering_initial: lastname?.[0] }

        if (firstname) {
          tmp.rendering_initial = tmp.rendering_initial + firstname?.[0]
        }
        tmp.rendering_initial = tmp.rendering_initial.toUpperCase()
      }
      let statusGroup = statuses[tmp.status]?.group
      if (settings?.showSmartCensus && tmp?.isSmartCensusVisit && statusGroup == 'Available') {
        statusGroup = statuses['Checked In']?.group
      }
      tmp = { ...tmp, statusGroup: statusGroup }

      inPatients.push(tmp)
      encounters.push(tmp)
    }
  } catch (e) {
    console.log({ e })
  }
  return { inPatients, encounters }
}

export const processVisits = (visits, statuses, settings) => {
  let inPatients = []

  for (let tmp of visits) {
    if (tmp?.activities && tmp?.activities.length > 0) {
      tmp = {
        ...tmp,
        last_activity: tmp.activities[tmp.activities.length - 1].activity_date,
      }
    } else {
      tmp = { ...tmp, last_activity: '' }
    }

    let tmp_dx = []
    if (tmp.diagnosisCodeList && tmp.diagnosisCodeList.length > 0) {
      tmp_dx = tmp.diagnosisCodeList.filter((dx) => {
        return dx.code == tmp.diagnosis_codes[0]
      })
    }

    if (
      tmp.diagnosis_code_primary &&
      Array.isArray(tmp.diagnosis_code_primary) &&
      tmp.diagnosis_code_primary.length > 0
    ) {
      if (tmp.diagnosis_code_primary && tmp.diagnosis_code_primary[0]) {
        tmp = {
          ...tmp,
          diagnosis_code_primary: {
            description:
              tmp.diagnosis_code_primary && tmp.diagnosis_code_primary[0]
                ? tmp.diagnosis_code_primary?.[0].description
                : '',
            code: tmp.diagnosis_codes?.[0]
              ? tmp.diagnosis_codes?.[0]
              : tmp.diagnosis_code_primary.code,
          },
        }
      }
    } else if (
      (!tmp.diagnosis_code_primary || tmp.diagnosis_code_primary.length == 0) &&
      tmp.diagnosis_codes &&
      tmp.diagnosis_codes.length > 0
    ) {
      tmp = {
        ...tmp,
        diagnosis_code_primary: {
          description: '',
          code: tmp.diagnosis_codes?.[0] ? tmp.diagnosis_codes[0] : '',
        },
      }
    }

    if (tmp_dx && tmp_dx[0]) {
      tmp.diagnosis_code_primary = tmp_dx[0]
    }

    if (tmp.service_lines && tmp.service_lines.length > 0) {
      let service_lines = tmp.service_lines.filter((line) => line.procedure_code)
      tmp.service_lines = service_lines
    }
    if (tmp.rendering_provider_name) {
      let names = tmp.rendering_provider_name.split(' ')
      let firstname = names?.[1]
      let lastname = names?.[0]
      tmp = { ...tmp, rendering_initial: lastname?.[0] }

      if (firstname) {
        tmp.rendering_initial = tmp.rendering_initial + firstname?.[0]
      }
      tmp.rendering_initial = tmp.rendering_initial.toUpperCase()
    }
    if (tmp.chart_id) {
      if (tmp.chart.signed_on) {
        tmp['noteStatus'] = 'completed'
      } else {
        tmp['noteStatus'] = 'draft'
      }
    } else {
      tmp['noteStatus'] = 'no_notes'
    }
    tmp.visitId = tmp._id
    let statusGroup = statuses[tmp.status]?.group
    if (settings?.showSmartCensus && tmp?.isSmartCensusVisit && statusGroup == 'Available') {
      statusGroup = statuses['Checked In']?.group
    }
    tmp = { ...tmp, statusGroup: statusGroup }

    inPatients.push(tmp)
  }
  return inPatients
}

export const processVisitsV2 = (visits, statuses, settings) => {
  let inPatients = []

  for (let tmp of visits) {
    if (tmp?.activities && tmp?.activities.length > 0) {
      tmp = {
        ...tmp,
        last_activity: tmp.activities[tmp.activities.length - 1].activity_date,
      }
    } else {
      tmp = { ...tmp, last_activity: '' }
    }
    if (
      tmp.diagnosis_code_primary &&
      Array.isArray(tmp.diagnosis_code_primary) &&
      tmp.diagnosis_code_primary.length > 0
    ) {
      if (tmp.diagnosis_code_primary && tmp.diagnosis_code_primary[0]) {
        tmp = {
          ...tmp,
          diagnosis_code_primary: {
            description:
              tmp.diagnosis_code_primary && tmp.diagnosis_code_primary[0]
                ? tmp.diagnosis_code_primary?.[0].description
                : '',
            code: tmp.diagnosis_codes?.[0]
              ? tmp.diagnosis_codes?.[0]
              : tmp.diagnosis_code_primary.code,
          },
        }
      }
    } else if (
      (!tmp.diagnosis_code_primary || tmp.diagnosis_code_primary.length == 0) &&
      tmp.diagnosis_codes &&
      tmp.diagnosis_codes.length > 0
    ) {
      tmp = {
        ...tmp,
        diagnosis_code_primary: {
          description: '',
          code: tmp.diagnosis_codes?.[0] ? tmp.diagnosis_codes[0] : '',
        },
      }
    }

    if (tmp.service_lines && tmp.service_lines.length > 0) {
      let service_lines = tmp.service_lines.filter((line) => line.procedure_code)
      tmp.service_lines = service_lines
    }
    if (tmp.rendering_provider_name) {
      let names = tmp.rendering_provider_name.split(' ')
      let firstname = names?.[1]
      let lastname = names?.[0]
      tmp = { ...tmp, rendering_initial: lastname?.[0] }

      if (firstname) {
        tmp.rendering_initial = tmp.rendering_initial + firstname?.[0]
      }
      tmp.rendering_initial = tmp.rendering_initial.toUpperCase()
    }
    if (tmp.chart_id) {
      if (tmp.chart.signed_on) {
        tmp['noteStatus'] = 'completed'
      } else {
        tmp['noteStatus'] = 'draft'
      }
    } else if (tmp?.chart?._id) {
      if (tmp?.chart?.signed_on) {
        tmp['noteStatus'] = 'completed'
      } else {
        tmp['noteStatus'] = 'draft'
      }
    } else {
      tmp['noteStatus'] = 'no_notes'
    }
    tmp.visitId = tmp._id

    let statusGroup = statuses[tmp.status]?.group
    if (settings?.showSmartCensus && tmp?.isSmartCensusVisit && statusGroup == 'Available') {
      statusGroup = statuses['Checked In']?.group
    }
    tmp = { ...tmp, statusGroup: statusGroup }

    inPatients.push(tmp)
  }
  return inPatients
}
