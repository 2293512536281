import moment from 'moment'
import { checkItsHaveOnlyTextArea } from 'src/features/progressNote/progressNoteSlice'

export const createTemplateWithValue = (
  selectedTemplate,
  selectedSections,
  selectedSnippet = [],
) => {
  let tempDataFromTemplate = [...selectedTemplate?.data]

  // save data from selected sections, and marked unused sections as skipped
  tempDataFromTemplate?.forEach((item, i) => {
    const templateSectionIsSelected = selectedSections?.find((sec) => item?.title === sec?.title)
    if (!templateSectionIsSelected) {
      tempDataFromTemplate[i] = {
        ...tempDataFromTemplate[i],
        skip: true,
      }
      return
    }

    let selected_snippet = selectedSnippet?.filter(
      (item) => item?.section === templateSectionIsSelected?.title,
    )
    if (templateSectionIsSelected?.optional) {
      tempDataFromTemplate[i] = {
        ...item,
        values: templateSectionIsSelected?.values,
        skip: false,
        selected_snippet,
        isTemplate: templateSectionIsSelected?.isTemplate,
      }
    } else {
      tempDataFromTemplate[i] = {
        ...item,
        values: templateSectionIsSelected?.values,
        selected_snippet,
        skip: false,
      }
    }
  })

  return { ...selectedTemplate, data: tempDataFromTemplate }
}

export const chartArrayGenerater = (element) => {
  let chartTxtArray = []

  let isHaveOnlyTextarea = checkItsHaveOnlyTextArea(element)

  for (let section of element.sections) {
    if ((section.checkbox && !section.isChecked) || section.disable) {
      continue
    }
    if (section.isChecked || section?.options?.find((op) => op?.isChecked)) {
      chartTxtArray.push(section.label)
    }

    if (section.type == 'input') {
      let sectionLabel
      let sectionData = []
      let tabData = []
      for (let option of section.options) {
        if (option.inputType == 'label') {
          if (sectionLabel && sectionData.length > 0) {
            chartTxtArray.push(sectionLabel + ': ' + sectionData.join('; '))
          } else if (sectionData.length > 0) {
            chartTxtArray.push(sectionData.join('; '))
          }
          sectionLabel = option.label
          sectionData = []
        } else if (option.inputType == 'tabs') {
          option.options.forEach((t) => {
            tabData.push({
              label: option.label || '',
              tab: t,
              data: [],
            })
          })
        } else if (['text', 'textarea'].includes(option.inputType)) {
          if (option.value) {
            sectionData.push(option.label + ' ' + option.value + (option.uom ? option.uom : ''))
          } else {
            if (isHaveOnlyTextarea) {
              sectionData.push(' ')
            }
          }
        } else if (option.inputType == 'checkbox' && option.isChecked) {
          sectionData.push(option.label)
        } else if (option.inputType == 'plusminus') {
          if (option.plus) {
            sectionData.push('+' + option.label + (option.value ? ' - ' + option.value : ''))
          } else if (option.minus) {
            sectionData.push('-' + option.label + (option.value ? ' - ' + option.value : ''))
          }
        } else if (option.inputType == 'select' && option.value) {
          sectionData.push(option.label + ' ' + option.value)
        } else if (option.inputType == 'range' && option.value != undefined) {
          let printData =
            option.label + ' ' + option.value + (option.uom ? option.uom : '') + '/' + option.max
          if (option.tabIndex != undefined) {
            tabData[option.tabIndex].data.push(printData)
          } else {
            sectionData.push(printData)
          }
        } else if (option.inputType == 'numpad-decimal' && option.value) {
          sectionData.push(option.label + ' ' + option.value + (option.uom ? option.uom : ''))
        } else if (option.inputType == 'numpad-date' && option.value) {
          sectionData.push(option.label + ' ' + moment(option.value).format('M/D/YYYY'))
        } else if (option.inputType == 'calendar' && option.value) {
          sectionData.push(option.label + ' ' + moment(option.value).format('M/D/YYYY'))
        } else if (option.inputType == 'custom' && !(option.checkbox && !option.isChecked)) {
          let inputData = option.inputs.filter((i) => i.value || i.plus || i.minus)
          let plusMinus = inputData.findIndex((i) => i.inputType == 'plusminus')
          if (inputData.length > 0) {
            let tmpp =
              (plusMinus == -1 ? option.label + ' ' : '') +
              inputData
                .map(
                  (i) =>
                    (i.label && !(i.plus || i.minus) ? i.label + ' ' : '') +
                    (i.plus ? '+' + option.label : '') +
                    (i.minus ? '-' + option.label : '') +
                    (i.value
                      ? i.inputType == 'numpad-date' || i.inputType == 'calendar'
                        ? moment(i.value).format('M/D/YYYY')
                        : i.value
                      : '') +
                    (i.uom || ''),
                )
                .join(option.delimiter || ', ')
            sectionData.push(tmpp)
          } else if (option.isChecked) {
            sectionData.push(option.label)
          }
        } else if (option.value) {
          //   console.log("not mapped", option);
        } else {
          // console.log('nothing to do', option);
        }
      }

      let joinText = ''

      if (isHaveOnlyTextarea) {
        for (let txt of sectionData) {
          if (txt !== ' ') {
            joinText += txt + ';  '
          } else {
            joinText += ' '
          }
        }
      } else {
        joinText = sectionData.join('; ')
      }

      if ((section.isChecked || sectionLabel) && sectionData.length > 0) {
        chartTxtArray.push((sectionLabel ? sectionLabel + ': ' : '') + joinText)
      } else if (sectionData.length > 0) {
        chartTxtArray.push((section.label ? section.label + ': ' : '') + joinText)
      }
      if (tabData.length > 0) {
        tabData.forEach((t) => {
          if (t.data.length > 0) {
            chartTxtArray.push((t.label ? t.label + ' - ' : '') + t.tab + ': ' + t.data.join('; '))
          }
        })
      }
    } else if (section.type === 'radio' && section.value) {
      let sectionData = []
      if (section.options) {
        let option = section.options.find(
          (o) => o.label == section.value || o.value == section.value,
        )
        if (option && option.inputs) {
          let inputData = option.inputs.filter((i) => i.value || i.plus || i.minus)
          let plusMinus = inputData.findIndex((i) => i.inputType === 'plusminus')
          if (inputData.length > 0) {
            let tmpp =
              (plusMinus === -1 ? option.label + ' ' : '') +
              inputData
                .map(
                  (i) =>
                    (i.label && !(i.plus || i.minus) ? i.label + ' ' : '') +
                    (i.plus ? '+' + option.label : '') +
                    (i.minus ? '-' + option.label : '') +
                    (i.value
                      ? i.inputType == 'numpad-date' || i.inputType == 'calendar'
                        ? moment(i.value).format('M/D/YYYY')
                        : i.value
                      : ''),
                )
                .join(' / ')
            sectionData.push(tmpp)
          } else {
            sectionData.push(section.value)
          }
        } else if (option) {
          sectionData.push(section.value)
        } else {
          console.log('not matched', option)
        }
      }
      if (sectionData.length > 0)
        chartTxtArray.push((section.label ? section.label + ': ' : '') + sectionData.join('; '))
    } else if (
      (section.type === 'diagnosisPlan' || section?.type === 'diagnosis') &&
      section.options &&
      section.options.length > 0
    ) {
      chartTxtArray.push(section.label)
      for (let option of section.options) {
        if (option.exclude) {
          continue
        }
        chartTxtArray.push(
          option.code +
            ': ' +
            option.description +
            ' ' +
            (option.status
              ? option.status.charAt(0).toUpperCase() + option.status.slice(1) + ': '
              : '') +
            (option.plan ? option.plan : '-'),
        )
      }
    } else if (['conditions', 'allergies'].includes(section.type)) {
      if (section.NKDA || (section.options && section.options.length > 0))
        chartTxtArray.push(section.label ? section.label + ': ' : '')
      if (section.NKDA) chartTxtArray.push('NKDA')
      for (let option of section.options) {
        chartTxtArray.push((option.clinicalStatus || option.status) + ': ' + option.description)
      }
    } else if (section.type === 'medications') {
      if (section.options && section.options.length > 0)
        chartTxtArray.push(section.label ? section.label + ': ' : '')
      if (section.review) chartTxtArray.push(section.reviewLabel)
      for (let option of section.options) {
        chartTxtArray.push(
          option.status +
            ': ' +
            option.description +
            (option.directions ? ' - ' + option.directions : ''),
        )
      }
    } else if (section.type === 'immunizations') {
      if (section.options && section.options.length > 0)
        chartTxtArray.push(section.label ? section.label + ': ' : '')
      for (let option of section.options) {
        chartTxtArray.push(
          option.consentStatus +
            ': ' +
            moment(option.administrationDateTime).format('MM/DD/yyyy') +
            (option.immunization ? ' - ' + option.immunization : ''),
        )
      }
    } else if (!section.type && section.label) {
      chartTxtArray.push(section.label)
    }
  }

  return chartTxtArray
}

export const uniqueJsonArray = (array) => {
  let uniqueArrayStrings = new Set(array.map(JSON.stringify))
  let uniqueArrayStringsArray = Array.from(uniqueArrayStrings)
  let uniqueArrayObjects = uniqueArrayStringsArray.map(JSON.parse)

  return uniqueArrayObjects
}

export const truncate = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) + '....' : str
}

export const mergeTwoTemplate = (array1, array2) => {
  let origArr = [...array1]
  let updatingArr = [...array2]
  for (let i = 0, l = origArr.length; i < l; i++) {
    for (let j = 0, ll = updatingArr.length; j < ll; j++) {
      if (origArr[i].title === updatingArr[j].title) {
        origArr.splice(i, 1, updatingArr[j])
        break
      }
    }
  }

  return origArr
}

export const patientDataFormat = (data) => {
  return {
    ...data,
    patient_firstname: data?.first_name,
    patient_lastname: data?.last_name,
    patient_middlename: data?.middle_name,
    patient_dob: data?.dob,
    patient_cellphone: data?.cellphone,
    patient_id: data?._id,
    facility_id: data.patient_admit_info?.at(-1).facility_id,
  }
}

export const isNoteValide = (data) => {
  let requiredSections = data?.filter(
    (item) =>
      !item?.optional &&
      item?.title !== 'Diagnosis (ICD10)' &&
      item?.title !== 'E&M and procedure codes',
  )
  let valuedSection = requiredSections?.filter((item) => item?.values?.find((val) => val !== ''))

  return requiredSections?.length === valuedSection?.length
}

export const addCodeValidation = (codes, serviceDate) => {
  return codes?.map((item) => {
    let isValid = true
    let thisItem = { ...item }
    if (item?.from_date || item?.to_date) {
      thisItem.effective_from = item?.from_date
      thisItem.effective_to = item?.to_date

      if (thisItem?.to_date) {
        isValid = moment(serviceDate).isBefore(thisItem?.to_date)
      }
    } else {
      if ((thisItem?.effective_from || thisItem?.effective_to) && serviceDate) {
        if (thisItem?.effective_to) {
          isValid = moment(serviceDate).isBefore(thisItem?.effective_to)
        }
      }
    }

    return {
      ...thisItem,
      isValid,
    }
  })
}

export const checkCodeValidation = (codes, serviceDate) => {
  let validatedCodes = addCodeValidation(codes, serviceDate)

  let isValid = validatedCodes?.find((item) => item.isValid === false)

  return !Boolean(isValid)
}

export const getInValidCodes = (codes, serviceDate) => {
  let validatedCodes = addCodeValidation(codes, serviceDate)

  return validatedCodes?.filter((item) => item.isValid === false)
}
