import {
  getCollector,
  postCollector,
  putCollector,
  bulkPostCollector,
  delCollector,
  putCollectorArr,
} from 'src/apiHelper/collectorApi'
import {
  deleteFromSettingsServer,
  getFromSettingsServer,
  getSettings,
  postToSettingsServer,
  putDataToSettingsServer,
} from 'src/apiHelper/settingsApi'
import {
  getFromSocketServer,
  postToSocketServer,
  putDataToSocketServer,
} from 'src/apiHelper/socketApi'
import { getFusion, postFusion } from 'src/apiHelper/fusionApi'

export const getProgressNoteTemplates = () => {
  return getFromSettingsServer(`currentpractice/medicalcharttemplates?&enabled=true`)
}

export const getMips = (data) => postFusion('v1/mips/measures/available', data)
export const getMySnippets = (providerId) => getFromSettingsServer(`currentprovider/snippets`)
export const addMySnippets = (data) => postToSettingsServer(`currentprovider/snippets`, data)
export const removeThisSnippets = (id) => deleteFromSettingsServer(`currentprovider/snippets/${id}`)
export const editThisSnippets = (id, data) =>
  putDataToSettingsServer(`currentprovider/snippets/${id}`, data)
export const sortThisSnippets = (id, data) =>
  putDataToSettingsServer(`currentprovider/snippets/sort/${id}`, data)

export const sortAllSnippets = (data) => postToSettingsServer(`currentprovider/snippets/sort`, data)

export const getProgressNoteImage = (charge_id) =>
  getCollector(`/charges/${charge_id}/chart/image?`)
export const getProgressNoteRegenerate = (charge_id) =>
  getCollector(`/charges/${charge_id}/chart/regenerate`)
export const getProgressNoteConvertV2 = (charge_id) =>
  getCollector(`/charges/${charge_id}/chart/convertv2text`)

export const getFavProcedures = (data) => {
  return getSettings(
    `currentprovider/favprocedures?q=${data?.q ? data?.q : ''}&place_of_service=${
      data?.place_of_service ? data?.place_of_service : ''
    }${data?.template ? `&template=${data?.template}` : ''}`,
  )
}

export const getFavDiagnosis = (data) => {
  return getSettings(
    `currentprovider/favdiagnosis?q=${data?.q ? data?.q : ''}
      ${data?.template ? `&template=${data?.template}` : ''}`,
  )
}

export const getVisitDiagnosis = (charge_id) =>
  getCollector(`/dailycensus/visits/${charge_id}/diagnosis`)

export const downloadNotesPDF = (charge_id) =>
  getCollector(`/charges/${charge_id}/chart/image?fileFormat=pdf`)

export const cloneNotes = (charge_id, data) =>
  postCollector(`/dailycensus/visits/${charge_id}/clonenotes`, data)

export const getDocumentUploadList = (facility_Ids, service_Date) =>
  getCollector(`/dailycensus/documents?service_date=${service_Date}&facilityIds=${facility_Ids}`)

export const downloadAllNotes = (facilityId, serviceDate) =>
  getCollector(
    `/charges/export/charts?&facilityId=${facilityId ? facilityId : ''}&serviceDate=${
      serviceDate ? serviceDate : ''
    }&fileFormat=pdf`,
    {
      responseType: 'blob',
      timeout: 30000,
    },
  )

export const downloadAllNotesByFilter = (facilityId, serviceDate, filter) =>
  getCollector(
    `/charges/export/charts/filter?&facilityId=${facilityId ? facilityId : ''}&serviceDate=${
      serviceDate ? serviceDate : ''
    }&filterBy=${filter ? filter : 'all'}&fileFormat=pdf`,
    {
      responseType: 'blob',
      timeout: 30000,
    },
  ).catch((e) => {
    return e
  })

export const downloadAllNotesByIds = (visit_ids, includeDatePath = true) =>
  getCollector(
    `/charges/export/charts/series?fileFormat=pdf&visit_ids=${visit_ids}&includeDatePath=${includeDatePath}`,
    {
      responseType: 'blob',
      timeout: 30000,
    },
  ).catch((e) => {
    return e
  })

export const getVisitProcedures = (charge_id) =>
  getCollector(`/dailycensus/visits/${charge_id}/procedures`)

export const sherpaAIPredict = (charge_id) => getCollector(`/charges/${charge_id}/chart/predict`)

export const getImageFile = (filename) =>
  getCollector(`/dailycensus/files/image/${filename}?optimized=false`)

export const deleteSingleDocument = (filename) => delCollector(`/facilities/${filename}/files`)

export const getVisitDetails = (data) => getCollector(`dailycensus/visits/${data.id}/series/v2`)
// TODO - consolidate these 2 identical calls
export const getVisitHistory = (visitid) => getCollector(`/dailycensus/visits/${visitid}/series/v2`)
export const setUserSetting = (data) => putDataToSettingsServer(`currentuser/settings`, data)

export const putProgressNoteProcedures = (charge_id, data) =>
  putCollector(`/charges/${charge_id}/procedures`, data)

export const putProgressNoteDiagnosiscodes = (charge_id, data) =>
  putCollector(`/charges/${charge_id}/diagnosiscodes`, data)

export const saveBillingNotes = (charge_id, data) => {
  if (charge_id) {
    return putCollector(`/charges/${charge_id}/billingnotes`, data)
  }
}

export const changeTeleHealth = (charge_id, data) =>
  putCollector(`/dailycensus/visits/${charge_id}/telehealth`, data)

export const changeAdmitReason = (charge_id, data) =>
  putCollector(`/dailycensus/visits/${charge_id}/admission_reason`, data).catch(() => {
    return { status: 'failure', reason: '500 from server' }
  })

export const changeHospiceRelated = (charge_id, data) =>
  putCollector(`/dailycensus/visits/${charge_id}/hospicerelated`, data)

export const changeBillingLegalEntity = (charge_id, data) =>
  putCollector(`/dailycensus/visits/${charge_id}/legalentity`, data)

export const changeSharedServiceProvider = (charge_id, data) =>
  putCollector(`/dailycensus/visits/${charge_id}/incidentprovider`, data)

export const changeCareCoordination = (charge_id, data) =>
  putCollector(`/dailycensus/visits/${charge_id}/carecoordination`, data)

export const saveFilesToVisit = (data) => putCollector(`/dailycensus/visits`, data)

export const getVisitCharts = (charge_id) => getCollector(`/charges/${charge_id}/chart`)
export const getPreviousNotes = (charge_id) =>
  getCollector(`/v2/progressnote/practices/previousnotes`)

export const getPatientsData = (currentDate, type) =>
  getCollector(`/v2/dashboard/practice/patients/${currentDate}/${type}`)
export const getEncounterData = (currentDate, type) =>
  getCollector(`/v2/dashboard/practice/encounters/${currentDate}/${type}`)
export const getRevenueData = (currentDate, type) =>
  getCollector(`/v2/dashboard/practices/revenue/${currentDate}/${type}`)
export const getRvuData = (currentDate, type) =>
  getCollector(`/v2/dashboard/practice/rvu/${currentDate}/${type}`)

export const saveChartProgressNote = (visit_id, data) =>
  putCollector(`charges/${visit_id}/chart`, data)
export const signChartProgressNote = (visit_id, data) =>
  postCollector(`charges/${visit_id}/chart/sign`, data || {})
export const checkMIPSProgressNote = (visit_id) =>
  getCollector(`dailycensus/visits/${visit_id}/mipsvalidate`, {})

export const billVisitSubmit = (visit_id) =>
  postCollector(`dailycensus/visits/${visit_id}/submitbill`, {})

export const setBulkVisitRecall = (body) =>
  bulkPostCollector(`dailycensus/v2/visits/bulkrecallbill`, body)

export const getConversations = (data) => {
  return getFromSocketServer(`/conversations?q=${data?.q}&page=${data?.page}&type=${data?.type}`)
}
export const listContactList = () => {
  return getSettings('currentpractice/contactlist')
}
export const listPlaceOfService = () => {
  return getSettings('search/searchPlaceOfService?q=&enabled=true&limit=100')
}

export const getCurrentUser = (config = {}) => getSettings('currentuser', config)

export const getPicture = (fileName) => {
  return getCollector('/dailycensus/files/image/' + fileName)
}

export const getReadConversation = (id) => {
  return getFromSocketServer(`/conversations/${id}/read`)
}

export const updateUnreadConversation = (id, userInfo) => {
  return putDataToSocketServer(`/conversations/${id}/viewed`, userInfo)
}
export const updateArchiveStatus = (req) => {
  return putDataToSocketServer(`/conversations/${req.id}/messagetype/${req.status}`, {})
}
export const createGroupConversation = (body) => postToSocketServer(`/conversations`, body)

export const createConversation = (conversation_id, body) =>
  postToSocketServer(`/conversations/${conversation_id}`, body)

export const updateConversation = (conversation_id, body) =>
  putDataToSocketServer(`/conversations/${conversation_id}/title`, body)

export const downloadFile = (fileName) => getFromSocketServer(`/conversations/chat/${fileName}`)
export const downloadFiles = (fileName) => getCollector(`/dailycensus/files/download/${fileName}`)

export const getFileSeries = (visit) =>
  getCollector(`dailycensus/visits/${visit}/files?series=true`)
export const getFileSeriesPatient = (patientId) => getCollector(`files/patient/${patientId}`)
export const getVisitAttachments = (visitId) => getCollector(`files/charge/${visitId}`)
export const getPatientData = (patientId) => getCollector(`/patients/v2/${patientId}`)
export const saveMIIPSData = (visitId, data) => putCollectorArr(`/charges/${visitId}/mips`, data)

export const getPractice = (config = {}) =>
  getSettings('currentuser/practices?enabled=true&pagesize=10000000000', config)

export const searchCPT = (q, pos) =>
  getSettings(`currentprovider/procedures?q=${q}&place_of_service=${pos ? pos : ''}`)

export const getProviders = (config = {}) => getSettings('currentuser/providers', config)

export const currentProvider = () => getSettings('currentprovider')
export const currentPractice = () => getSettings('currentpractice')
export const currentSettings = () => getSettings('currentuser/settings')
export const currentRoles = () => getSettings('currentuser/roles')
export const currentPermissions = () => getSettings('currentuser/permissions')
export const currentFeatures = () => getSettings('currentuser/features')

export const getUnreadConversations = (data) => {
  return getFromSocketServer(
    `/conversations/unread?q=${data?.q}&page=${data?.page}&type=${data?.type}`,
  )
}

export const getProviderConversations = (data) => {
  return getFromSocketServer(
    `/conversations/providerList?q=${data?.q}&page=${data?.page}&type=${data?.type}`,
  )
}

export const postCensusConvo = (visitId) => {
  return postCollector(`/dailycensus/visits/${visitId}/conversation`)
}

export const setVisitVoid = (visitId) => {
  return postCollector(`/dailycensus/visits/${visitId}/void`)
}

export const postMessageCensusConvo = (convoId, data) => {
  return postToSocketServer(`/conversations/${convoId}`, data)
}

export const postBulkAddendums = (data) => {
  return postCollector(`/charges/chart/bulkaddendums`, data)
}

export const favPatient = (id) => {
  return postCollector(`dailycensus/patients/${id}/favorite `)
}

export const unFavPatient = (id) => {
  return delCollector(`dailycensus/patients/${id}/favorite`)
}

export const getPatientAdvSearch = (data) =>
  getCollector(
    `dailycensus/patientsDuplicateCheck?&lastname=${data?.lastname}&dob=${data?.dob}&gender=${data?.gender}`,
  )
export const deleteNotes = (chart_id) => {
  return delCollector(`/v2/progressnote/${chart_id}`)
}

export const getPatientsVisitDetailsID = (id) => getCollector(`dailycensus/visits/${id}`)
export const getReason = (id = {}) => getSettings(`settings/taskstatus/${id}/reasons`)
export const setStatus = (data, taskId) => {
  return postCollector(`tasks/${taskId}/setstatus`, data)
}
export const setChargeClaimStatus = (data) => {
  return postCollector(`charges/chargeclaimstatus`, data)
}
export const getInitialprocedures = () => getCollector(`dailycensus/visits/initialprocedures`)

export const updateCareTeam = (visitId, data) =>
  putCollector(`dailycensus/visits/${visitId}/careteam`, data)

export const favoriteFacilityUpdate = (facility_id) =>
  putDataToSettingsServer('currentprovider/favfacility/' + facility_id)
export const unfavoriteFacilityUpdate = (facility_id) =>
  deleteFromSettingsServer('currentprovider/favfacility/' + facility_id)

export const favoriteModifier = (modifier) =>
  putDataToSettingsServer('currentprovider/favmodifier/' + modifier)
export const unfavoriteModifier = (modifier) =>
  deleteFromSettingsServer('currentprovider/favmodifier/' + modifier)
